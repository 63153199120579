<template>
  <BasePage>
    <PageTitle :title="`Agregar Prospecto ✨`" />
    <div class="max-w mx-auto w-full px-4 py-2">
      <form @submit.prevent="add">
        <FormComponent
        :show-asign-agent-button="modifyAgent" :can-add="showAddSources"
        :show-data="this.showData"
        :form-data_="this.formData.client"
        @update:model="updateFormData"
        />
        <!-- nameOrCurpCorrection": false,
        "nssUnification": false,
        "unknownWeeksAcknowledgment": false -->
        <button :disabled="isLoading" @click.prevent.stop="searchProspect" class="btn bg-indigo-500 hover:bg-indigo-600 text-white my-2">
          <span v-if="!isLoading">{{ 'Buscar' }}</span>
          <span v-else>
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
          </span>
        </button>
        <div v-if="this.showData" class="grid grid-cols-1 sm:grid-cols-6 gap-2">
          <div class="sm:col-span-3 ">
              <ProspectionValuesTable 
              :title="'Valores'"
              :key="this.componentKey"
              :is-loading="isLoading" :model="this.formData.values" @update:model="updateList"
              :list_="this.formData.values"/>
          </div>  
          <div class="sm:col-span-3" >
              <AplicableProductsTable 
              :is-board="false"
              :key="this.componentKey"
              :title="'Productos Aplicables'"
              :title-modal="'Agregar Producto'"
              :is-loading="isLoading" :model="this.formData.aplicableProducts" @update:model="updateList"
              :list_="this.formData.aplicableProducts" :full-list="this.products"/>
          </div>
        </div>
        <div v-if="this.showData" class="grid grid-cols-1 sm:grid-cols-6 gap-2 mt-2">
          <div class="sm:col-span-6">
            <ErrorsTable 
              :title="'Errores'"
              :key="this.componentKey"
              :is-loading="isLoading" :model="this.formData.values" @update:model="updateList"
              :list_="this.formData.errors"/>
          </div>
        </div>
        <button v-if="this.showData" @click="downloadPdf">Download PDF Semanas</button>

        <PdfDocument v-if="this.showData && this.documentLoadingSuccess" :documentUrl="this.formData.documentUrl" @continue-form="console.log(1)" :public-key="'1'" :isLoading="false"/>
        <div v-if="this.showData" class="flex">
          <button :disabled="isLoading" @click.prevent.stop="sendToClient" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
            <span v-if="!isLoading">{{ 'Enviar Al Cliente' }}</span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </button>
          <button :disabled="isLoading" @click.prevent.stop="download" class="ml-2 btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
            <span v-if="!isLoading">{{ 'Descargar' }}</span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </button>
          <button v-if="showAdd" :disabled="isLoading" class="ml-2 btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
            <span v-if="!isLoading">{{ $t('buttons.save') }}</span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </button>
        </div>
      </form>
    </div>
  </BasePage>
</template>
  
  <script>
  import Swal from 'sweetalert2';

  import { postData } from '@/utils/postData.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';

  import BasePage from '@/components/BasePage.vue';
  import api from '@/interceptors/axiosInterceptos'; 
  import PageTitle from '@/components/PageTitle.vue';
  import PdfDocument from '@/components/PdfDocument';
  import ErrorsTable from '@/partials/prospects/create/ErrorsTable.vue';
  import FormComponent from '@/partials/prospects/create/FormComponent.vue';
  import ProspectionValuesTable from '@/partials/prospects/create/ProspectionValuesTable.vue';
  import AplicableProductsTable from '@/partials/prospects/create/AplicableProductsTable.vue';

  export default {
    name: 'CreateProspectPage',
    components: {
      BasePage,
      PageTitle,
      ErrorsTable,
      PdfDocument,
      FormComponent,
      ProspectionValuesTable,
      AplicableProductsTable,
    },
    data(){
      return {
        modifyAgent: false,
        showAddSources: false,
        showAdd: false,
        products: [],
        documentLoadingSuccess: false,
        componentKey: 0,
        showData: false,
        isLoading: false,
        formData: {
          client: {
            curp: '',
            email: '',
            phone: '',
            sourceId: null,
            agentId: null,
            automaticAsignation: true,
          },
          documentUrl: null,
          values: [],
          aplicableProducts: [],
          errors: [],
        }
      }
    },
    methods: {
      async getProducts() {
        this.products = await fetchData(`products`, 'products', 'products', this);
      },
      async getD(){
        const token = localStorage.getItem('token');
        const response = await api.get(`${process.env.API_URL}/prospection/weeks-document/${this.formData.client.curp}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "authorization": `Bearer ${token}`,
          },
          responseType: 'blob'
        }).then(response => {
          // Set the document content received from the backend
          this.documentLoadingSuccess = true;
          this.formData.documentUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          
        })
        .catch(error => {
          console.error('Error fetching document:', error);
          this.documentLoadingSuccess = false;
          this.error = true;
          // Handle error if needed
        });
      },
      downloadPdf() {
        const link = document.createElement('a');
        link.href = this.formData.documentUrl;
        link.download = 'downloaded.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      async searchProspect(){
        this.documentLoadingSuccess = false;
        this.formData.values = [];
        this.formData.aplicableProducts = [];
        this.formData.errors = [];
        this.formData.documentUrl = null;
        this.componentKey += 1;
        try {
          this.isLoading = true;
          const token = localStorage.getItem('token');
          const headers = { 
              "authorization": `Bearer ${token}`,
          };
          const response = await api.get(`${process.env.API_URL}/prospection/find-products/${this.formData.client.curp}/notificaciones@afieconsultores.com/${'a'}`, { headers });
          
          if(response.status == 200){
              this.formData.values = response.data.values;
              this.formData.aplicableProducts = response.data.aplicableProducts;
              this.formData.errors = response.data.errors;
              this.componentKey += 1;
              await this.getD()
          }
          this.showData = true;
        } catch (error) {
          Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: error.response.statusText,
          });
        }
        this.isLoading = false;
      },
      transformValue(values) {
        return values.reduce((acc, obj) => {
          if (obj.items && Array.isArray(obj.items)) {
            obj.items.forEach(item => {
              if (item.label !== undefined && item.value !== undefined) {
                acc[item.label] = item.value;
              }
            });
          }
          return acc;
        }, {});
      },
      async add(){
        this.isLoading = true;
        var client = this.formData.client
        var {name, lastName, rfc, age, law73, currentlyActive, ...values} = this.transformValue(this.formData.values)
        
        client.name = name
        client.lastName = lastName
        client.rfc = rfc
        values.requestedErrors = JSON.stringify(this.formData.errors)
        values.law73 = law73 !== null ? law73 === 73 : null
        values.currentlyActive = currentlyActive === 'Activo'
        values.requestedDate = new Date()
        const body = {
          client: client,
          values: values,
          applicableProducts: this.formData.aplicableProducts
        }
        const response = await postData('prospects', body, this, true);
        if (!response) {
          return;
        };
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'El prospecto se a creado exitosamente.',
        });
        this.isLoading = false;
        this.$router.push('/prospects');
      },
      isValidEmail(email) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email);
      },
      async sendToClient(){
        if (this.formData.client.email == null || this.formData.client.email == "" || !this.isValidEmail(this.formData.client.email)){
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Proporciona un email y telefono por favor',
            
          })
          return;
        }
        const response = await postData('prospection/download-report', {
          sendByEmail: true,
          email: this.formData.client.email,
          phone: this.formData.client.phone,
          curp: this.formData.client.curp,
          values: this.formData.values,
          aplicableProducts: this.formData.aplicableProducts,
          errors: this.formData.errors,
        }, this, true);
        if (!response) {
          return;
        };
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'El reporte se a enviado exitosamente.',
        });
      },
      async download(){
        const response = await postData('prospection/download-report', {
          sendByEmail: false,
          curp: this.formData.client.curp,
          values: this.formData.values,
          aplicableProducts: this.formData.aplicableProducts,
          errors: this.formData.errors,
        }, this, true);
        if (!response) {
          return;
        };
        this.isLoading = true;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('path', `${this.formData.client.curp}/report.pdf`);
        
        try {
          const response = await api.post(`${process.env.API_URL}/documents/stream/${this.formData.client.curp}%2Freport.pdf`, formData,{
          
            headers: {
              'Content-Type': 'multipart/form-data',
              "authorization": `Bearer ${token}`,
            },
            responseType: 'blob'
          })
          
          const documentUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = documentUrl;
          link.download = 'downloaded.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.statusText,
            
          })
        } 
        this.isLoading = false;
      },
      updateFormData(updated){
        this.formData.client.curp = updated.curp;
        this.formData.client.email = updated.email;
        this.formData.client.phone = updated.phone;
        this.formData.client.sourceId = updated.source;
        this.formData.client.agentId = updated.agentId;
      },
      updateList(updated, propName){
          this.formData[propName] = updated;
      },
    },
    created(){
      hasSpecificPermission('showAddSources', 'add', ["add_sources"], this)
      hasSpecificPermission('showAdd', 'add', ["add_prospect"], this)
      hasSpecificPermission('modifyAgent', 'add', ["modify_agent_prospect_at_creation"], this)
      loadFromLocalStorage('products', 'products', this);
      this.getProducts()
    }
  }
  </script>