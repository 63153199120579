<template>
    <label class="block text-sm font-medium mb-1">
        {{ $t('labels.category') }}:
    </label>
    <div class="flex w-full">
        
        <input v-if="this.categories?.length < 1" id="email" class="form-input w-full" type="text" placeholder="Crea al menos una categoria" :disabled="true" />
        
        <DropdownFull v-else 
        :disable-inputs="disableInputs"
        :dropdownOptions="this.categories" 
        :init-value="this.initValue"
        :model="this.category" @update:model="updateCategories" />

        <div class="pr-2"></div>
        <button v-if="!disableInputs" class="text-white-500 hover:text-white-600 rounded-full">

            <svg @click.stop.prevent="feedbackModalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
        </button>
        <ModalBasic id="feedback-modal" :modalOpen="feedbackModalOpen" @close-modal="feedbackModalOpen = false" title="Agregar Categoria">
            <!-- Modal content -->
            <CategoriesForm :categories_="this.categories" @add-categories:value="add" />
        </ModalBasic>
    </div>
</template>

<script>
    import ModalBasic from '@/components/ModalBasic.vue'
    import DropdownFull from '@/components/DropdownFull.vue';
    import CategoriesForm from '@/partials/categories/CategoriesForm.vue'
    
    export default {
        name: 'CategoriesComponent',
        props: {
            categories_: Array,
            initValue: Number,
            disableInputs: {
                type: Boolean,
                default: function(){
                    return false
                }
            },
        },
        components: {
            ModalBasic,
            DropdownFull,
            CategoriesForm,
        },
        data(){
            return {
                categories: this.categories_,
                category: null,
                feedbackModalOpen: false
            }
        },
        methods: {
            updateCategories(updated) {
                this.category = updated.name;
                this.$emit('update:model', this.category);
            },
            add(element){
                this.categories = element.categories
                this.category = element.category
                this.feedbackModalOpen = false
            }
        }
    }
</script>