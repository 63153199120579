<template>
  <ModalBasic :key="componentKey" id="modal" :modalOpen="addModalOpen" @close-modal="addModalOpen = false" title="Agregar Documento">
    <!-- Modal content -->
    <form @submit.prevent="addDocument">
      <div class="px-5 py-4">
        <div class="space-y-3">
          <div>
              <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
              <input id="name" v-model="document.name" class="form-input w-full px-2 py-1" type="text" required />
          </div>
          <div>
              <label class="block text-sm font-medium mb-1" for="description">Descripcion</label>
              <textarea id="description" v-model="document.description" class="form-textarea w-full px-2 py-1" placeholder="Descripcion" rows="4" :disabled="document.optionsNA"></textarea>
          </div>
          <div class="form-check">
            <input class="form-checkbox" type="checkbox" value="" id="flexCheckDefault" v-model="document.requestDuringSignature">
            <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Solicitar durante el proceso de firma</span>
          </div>
            <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-4 mb-4 gap-y-4" >
              <div
              class="text-xs text-black"
              >
                <label class="block mb-1 text-rose-500">Formatos</label>
                <div v-for="(p, index) in ['.png', '.jpg', '.pdf']" :key="index" class="text-white mb-2">
                <input class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" 
                :checked="document.formats.includes(p)"
                type="checkbox"  @change="updateModel(p)"> 
                {{ p }}
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
        <div class="flex flex-wrap justify-end space-x-2">
            <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {addModalOpen = false}" />
        </div>
      </div>
    </form>
  </ModalBasic>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Documentos</h2>
        <form @submit.prevent="add">
          <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
              <div v-if="canEdit" class="sm:col-span-3" > 
                <label class="block text-sm font-medium mb-1">
                    {{ $t('labels.documents') }}:
                </label>
                <div class="flex w-full">
                    
                  <input v-if="this.documents?.length < 1" id="document" class="form-input w-full" type="text" placeholder="Crea al menos un documento" :disabled="true" />
                  
                  <DropdownFullQuestions v-else
                  :property-key="'name'"
                  :dropdownOptions="this.documents" 
                  :model="this.formData.document" @update:model="updateSelectedDocument" />
  
                  <div class="pr-2"></div>
                  <button class="text-white-500 hover:text-white-600 rounded-full">
                    <svg @click.stop="addModalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                  </button>
                </div>
                <button :disabled="isLoading || this.documents?.length < 1" @click.stop="addDocumentToProduct" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                  <span v-if="!isLoading">{{ $t('buttons.add') }}</span>
                  <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                  </span>
                </button>
              </div>
              <div class="sm:col-span-3" > 
                  <SubCategoriesTable 
                  :is-draggable="false"
                  :can-edit="canEdit"
                  :title="'Documentos del producto'" 
                  :empty-label="'Selecciona un documento del dropdown para agregarlo a los documentos del producto'"
                  :property-key="'document.name'"
                  :values="this.productDocuments" :is-loading="isLoading" 
                  @update:model="deleteProductDocument"
                  @updateorder:model=update
                  />
              </div>
          </div>
      </form>
      </div>
    </div>  
  </template>
  
  <script>
  import { editData } from '@/utils/editData.js';
  import { postData } from '@/utils/postData.js';
  import { deleteData } from '@/utils/deleteData.js';
  
  import Swal from 'sweetalert2';
  import ModalBasic from '@/components/ModalBasic.vue';
  import SubCategoriesTable from './SubCategoriesTable.vue';
  import DropdownFullQuestions from './DropdownFullQuestions.vue';
  import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
  
  export default {
    name: 'DocumentsPanel',
    components: {  
        ModalBasic,
        SubmitMainButton,
        SubCategoriesTable,
        DropdownFullQuestions,
    },
    props: {
      productId: String,
      documents_: Array,
      productDocuments_: Array,
      canEdit: {
        type: Boolean,
        default: function(){
          return false
        }
      },
    },
    data(){
      return {
        componentKey: 0,
        addModalOpen: false,
        documents: this.documents_,
        productDocuments: this.productDocuments_,
        isLoading: false,
        formData: {
          document: this.documents_.length > 0 ?this.documents_[0] : null,
          expenses: []
        },
        document: {
            name: '',
            description: '',
            requestDuringSignature: false,
            formats: ['.pdf'],
        }
      }
    },
    methods: {
      updateModel(option){
        const index = this.document.formats.indexOf(option);
        if (index === -1) {
            // If the option is not already in the array, push it
            this.document.formats.push(option);
        } else {
            // If the option is already in the array, remove it
            this.document.formats.splice(index, 1);
        }   
      },
      createDocument(){
        this.$router.push('/documents/create-document'); // Or use a custom route if defined
      },
      async addDocument(){
        const document = {
          name: this.document.name,
          description: this.document.options,
          requestDuringSignature: this.document.requestDuringSignature,
          formats: this.document.formats
        }
        const response = await postData('documents', document, this);
        if (!response) {
            return
        };
        this.documents.push(response);
        this.formData.document = response;
        await this.addDocumentToProduct();
        this.document = {
            name: '',
            description: '',
            requestDuringSignature: false,
            formats: ['.pdf'],
        };
        this.addModalOpen = false;
      },
      async deleteProductDocument(index){
        const id = this.productDocuments[index].id
        const response = await deleteData('product-documents', id, this, 'El documento del producto se a eliminado exitosamente.', true, true)
        if(!response){
            return
        }
        this.productDocuments.splice(index, 1);
        localStorage.setItem('productDocuments', JSON.stringify(this.productDocuments));
      },
      async addDocumentToProduct(){
        const body = {
          productId: this.productId,
          documentId: this.formData.document.id
        }
        const response = await postData('product-documents', body, this);
        if (!response) {
          return
        };
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'El documento se agrego al producto.',
        });
        this.productDocuments.push(response);
        localStorage.setItem('productDocuments', JSON.stringify(this.productDocuments));
      },
      async update(elements){
        const productId = this.$route.params.id;
        var body = [];
        for (let i = 0; i < elements.length; i++) {
          body.push({
            id: elements[i].id,
            index: i + 1,
            documentId: elements[i].document.id,
            productId: productId,
          });
        }
        const response = await editData(`product-documents`, body, 'El orden se modifico correctamente.', 'productDocuments', 'productDocuments', this, true);
        if (!response) {
          return
        };
        this.productDocuments = response;
        localStorage.setItem('productDocuments', JSON.stringify(this.productDocuments));
        this.$emit('forceRender');
      },
      updateSelectedDocument(document){
        this.formData.document = document;
      },
    },
  }
  </script>