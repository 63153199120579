<template>
    <div class="pb-2">
        <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{{this.label}}</div>
        <form class="relative ">
            <input id="action-search" class="form-input w-full pl-9 bg-white dark:bg-slate-800" type="text" v-model="filter" @input="updateModel"/>
            <button class="absolute inset-0 right-auto group" type="submit" aria-label="Search"></button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'StringFilter',
    props:{
        filter_: String,
        label: String
    },
    data(){
        return {
            filter: this.filter_,
        }
    },
    methods: {
        updateModel(value) {
            this.$emit('update:filter', this.filter);
        },
    }
}
</script>