<template>
  <ModalBasic id="fee-category-modal" :modalOpen="feeClassOpen" @close-modal="feeClassOpen = false" :title="titleModal">
      <!-- Modal content -->
    <form @submit.prevent="addFeeClass">
      <div class="px-5 py-4">
          <div class="space-y-3">
            <div>
                <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                <input id="name" v-model="feeClassName" class="form-input w-full px-2 py-1" type="text" required />
            </div>
          </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div class="flex flex-wrap justify-end space-x-2">
              <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {feeClassOpen = false}"/>
          </div>
      </div>
    </form>
  </ModalBasic>
  <ModalBasic id="fee-modal" :modalOpen="feeAmountOpen" @close-modal="feeAmountOpen = false" title=''>
      <!-- Modal content -->
    <form @submit.prevent="addFeeAmount">
      <div class="px-5 py-4">
          <div class="space-y-3">
            <div>
                <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                <input id="name" v-model="feeAmountName" class="form-input w-full px-2 py-1" type="text" required />
            </div>
            <div>
                <label class="block text-sm font-medium mb-1" for="name">Cantidad <span class="text-rose-500">*</span></label>
                <input id="name" v-model="feeAmount" class="form-input w-full px-2 py-1" type="number" step="0.0001" :disabled="feeVariable" required />
            </div>
            <div class="form-check">
              <input class="form-checkbox" type="checkbox" value="" id="flexCheckDefault" v-model="feeVariable">
              <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Variable</span>
            </div>
          </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div class="flex flex-wrap justify-end space-x-2">
              <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {feeAmountOpen = false}" />
          </div>
      </div>
    </form>
  </ModalBasic>

  <div class="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
    <header class="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
      <div class="flex justify-between">
        <h2 class="font-semibold text-slate-800 dark:text-slate-100 content-center">{{title}}</h2>
        <button v-if="canEdit" class="text-white-500 hover:text-white-600 rounded-full">
          <svg @click.stop.prevent="feeClassOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
        </button>
      </div>
    </header>
    <div class="p-3">
      <div v-for="(item, index) in this.feesList" :key="item.name">
        <header class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm font-semibold p-2">
          <div class="flex justify-between	">
            <span class="content-center">{{item.name}}</span>
            <div v-if="canEdit" class="flex">
              <button  class="text-white-500 hover:text-white-600 rounded-full">
                <svg @click.stop.prevent="feeAmountIndex = index, feeAmountOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
              </button>
              <div class="pr-2"></div>
              <DeleteButton :clickHandler="() => removeFeeClass(index)" />
            </div>
          </div>
        </header>
        <ul v-for="(comission, subindex) in item.fees" :key="comission.name" class="my-1">
          <!-- Item -->
          <li class="flex px-2">
            <div v-if="canEdit" class="w-8 h-8 rounded-full shrink-0 bg-indigo-500 my-2 mr-2">
              <DeleteButton :clickHandler="() => removeFeeAmount(index, subindex)" />
            </div>
            <div class="grow flex items-center border-b border-slate-100 dark:border-slate-700 text-sm py-2">
              <div class="grow flex justify-between">
                <div class="self-center">{{comission.name}}</div>
                <div class="flex shrink-0 self-end ml-2 text-center">
                  <span v-if="comission.variable">
                    Variable
                  </span>
                  <span v-else-if="comission.amount < 1">
                    {{(comission.amount * 100).toFixed(2)}}%
                  </span>
                  <span v-else>{{formatPrice(comission.amount)}}</span>
                </div>
              </div>
            </div>
          </li>
        </ul> 
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ModalBasic from '@/components/ModalBasic.vue';
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

export default {
  name: 'FeesTable',
  components: {
    ModalBasic,
    DeleteButton,
    SubmitMainButton
  },
  props: {
    isLoading: Boolean,
    title: String,
    titleModal: String,
    feesList_: {
      type: Array,
      default: function() {
        return [
          {'name': 'consultor', 'fees': [{'name': 'Contado', 'amount': 10000, 'variable': false}]}, 
          {'name': 'marketing', 'fees': [{'name': 'Contado', 'amount': 5000, 'variable': false}]}
        ];
      }
    },
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  setup() {
    const feeClassOpen = ref(false)
    const feeAmountOpen = ref(false)
    return {
      feeClassOpen,
      feeAmountOpen
    }  
  },
  data(){
    return {
      feeClassIndex: null,
      feeAmountIndex: null,
      feeClassName: '',
      feeAmountName: '',
      feeAmount: null,
      feeVariable: false,
      feesList: this.feesList_
    }
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2)
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    addFeeClass(){
      this.feesList.push({
        'name': this.feeClassName,
        'fees': []
      })
      this.feeClassName = '';
      this.feeClassOpen = false;
      this.updateModel(this.feesList);
    },
    removeFeeClass(index){
      this.feesList.splice(index, 1);
      this.updateModel(this.feesList);
    },
    addFeeAmount(){
      this.feesList[this.feeAmountIndex].fees.push({'name': this.feeAmountName, 'amount': this.feeAmount, 'variable': this.feeVariable})
      this.feeAmountIndex = null;
      this.feeAmountName = '';
      this.feeAmount = null;
      this.feeVariable = false;
      this.feeAmountOpen = false;
      this.updateModel(this.feesList);
    },
    removeFeeAmount(index, subIndex){
      this.feesList[index].fees.splice(subIndex, 1);
      this.updateModel(this.feesList);
    },
    updateModel(option) {
      this.$emit('update:model', option);
    },
  }
}
</script>