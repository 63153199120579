<template>
  <div class="col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
    <div class="flex flex-col h-full">
      <!-- Card top -->
      <div class="grow p-5">
        <!-- Image + name -->
        <header>
          <div class="flex justify-center mb-2">
            <router-link class="relative inline-flex items-start" :to="'#0'">
              <div class="absolute top-0 right-0 -mr-2 bg-white dark:bg-slate-700 rounded-full shadow" aria-hidden="true">
                <svg class="w-8 h-8 fill-current text-amber-500" viewBox="0 0 32 32">
                  <path d="M21 14.077a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 010 1.5 1.5 1.5 0 00-1.5 1.5.75.75 0 01-.75.75zM14 24.077a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                </svg>
              </div>
              <AvatarLetter :name="item.name" :size="64"/>
            </router-link>
          </div>
          <div class="text-center">
            <router-link class="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white" :to="'#0'">
              <h2 class="text-xl leading-snug justify-center font-semibold">{{item.name}}</h2>
            </router-link>
          </div>
        </header>
        <div class="text-center mt-2">
          <div class="text-sm">{{item.description}}</div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="border-t border-slate-200 dark:border-slate-700">
        <div class="flex divide-x divide-slate-200 dark:divide-slate-700">
          <button v-if="canEdit" @click.prevent.stop="editUser(item)" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
            <div class="flex items-center justify-center">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
            </svg>
              <span>Editar</span>
            </div>
          </button>
          <span v-if="isLoading && deletingId == item.id" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
            <div class="flex items-center justify-center">
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </div>
          </span>
          <button  v-else-if="canDelete" @click="deleteUser(item.id)" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
            <div class="flex items-center justify-center">
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path :class="'text-red-500'" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                <path :class="'text-red-500'" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
              </svg>
              <span>Eliminar</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AvatarLetter from '@/components/AvatarLetter.vue'
  export default {
    name: 'RolesTilesCard',
    props: {
      item: Object,
      isLoading: Boolean,
      deletingId: String,
      canEdit: {
        type: Boolean,
        default: function(){
          return false
        }
      },
      canDelete: {
        type: Boolean,
        default: function(){
          return false
        }
      }
    },
    components: {
      AvatarLetter
    },
    methods: {
      editUser(id){
        this.$emit('edit-role', id);
      },
      deleteUser(role) {
        this.$emit('delete-role', role);
      },
    }
  }
</script>