<template>
    <ModalBasic 
        :size="'max-w-7xl'"
        id="modal" :modalOpen="mapsModalOpen" @close-modal="mapsModalOpen = false" title="Ubicacion de Firma">
    <GMapMap
        :center="center"
        :zoom="15"
        map-type-id="terrain"
        style="width: 100vw; height: 900px"
      >
      <GMapMarker
          :key="marker.id"
          v-for="marker in markers"
          :position="marker.position"
      />
    </GMapMap>
  </ModalBasic> 
  <tr>
    
    <td class="px-2 first:pl-5 last:pr-5 py-3">
      <div class="font-medium text-sky-500">{{document.name}}</div>
    </td>        
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
      <div class="space-x-3">
        <button :disabled="document.documentExtension === null" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click.prevent.stop="mapsModalOpen = true">
          <span class="sr-only">Location</span>
          <font-awesome-icon v-if="!isLoading" class="h-4" :icon="['fas', 'location-dot']" />
          <font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
        </button>
        <!-- <button :disabled="document.documentExtension === null" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click="downloadD()">
          <span class="sr-only">Download</span>
          <svg v-if="!isLoading" class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 712">
            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
          </svg>
          <font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
        </button> -->
        <button :disabled="document.documentExtension === null" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click.stop="previewD(), modalOpen = true">
          <span class="sr-only">Preview</span>
          <font-awesome-icon class="h-4" v-if="!isLoading" :icon="['fas', 'eye']" />
          <font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
        </button>
      </div>
    </td>
  
  </tr>  
  <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, this.showData = false, this.documentLoadingSuccess = false" :title="'Preview Documento'" :size="'max-w-2xl'" :document-row="true">
    <!-- <PdfDocument v-if="this.showData && this.documentLoadingSuccess" :documentUrl="this.documentUrl" :isLoading="false"/>-->
    <div class="flex justify-center py-3">

      <div class="flex justify-center py-3">

        <iframe v-if="this.showData && this.documentLoadingSuccess" :src="this.documentUrl+ '#toolbar=1&navpanes=0'" width="100%" height="500px"  frameborder="0"></iframe> 
        <button v-else :disabled="true" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </button>
      </div>
    </div>

  </ModalBasic>
</template>

<script>
import { ref } from 'vue';

import ModalBasic from '@/components/ModalBasic.vue';
import { downloadDocument, handleFileChange, previewDocument, getDocument } from '@/utils/handleDocuments.js';

export default {
  name: 'ContractsTableItem',
  props: {
    todo: Object,
    document: Object,
    toSignDocument: Object,
    title: String
  },
  components:{
    ModalBasic,
  },
  setup() {
    const modalOpen = ref(false)
    const mapsModalOpen = ref(false)
    return {
      modalOpen,
      mapsModalOpen
    }  
  },
  data(){
    return {
      center: {lat: this.toSignDocument.latitude, lng: this.toSignDocument.longitude},
      markers: [
          {
          id: 'dfsldjl3r',
          position: {
              lat: this.toSignDocument.latitude, lng: this.toSignDocument.longitude
          },
          }
      ],
      isLoading: false,
      file: null,
      selectedFileName: null,
      documentLoadingSuccess: false,
      showData: false,
      documentUrl: null,
    }
  },
  emits: ["delete-document", "save-document"], 
  methods: {
    handleFC(event) {
      handleFileChange(event, this, this.document.formats)
    },
    async downloadD() {    
      await downloadDocument({
          clientId: this.todo.clientId,
          id: this.toSignDocument.clientProductId,
          name: this.document.name,
          documentExtension: '.pdf'
      }, this);
    },
    previewD() {    
      previewDocument({
          clientId: this.todo.clientId,
          id: this.toSignDocument.clientProductId,
          documentExtension: '.pdf'
      }, this)
    },
    async getD(document){
      console.log(this.toSignDocument)
      await getDocument(document, this)
    },
  },
}
</script>