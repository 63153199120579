<template>
    <form @submit.prevent="addCategory">
        <div class="px-5 py-4">
            <div class="space-y-3">
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="this.formData.name" class="form-input w-full px-2 py-1" type="text" required />
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
                <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {feedbackModalOpen = false}" />
            </div>
        </div>
    </form>
        
    
</template>

<script>
    import { postData } from '@/utils/postData.js';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    
    export default {
        name: 'CategoriesForm',
        props: {
            categories_: Array
        },
        components: {
            SubmitMainButton
        },
        data(){
            return {
                categories: this.categories_,
                formData: {
                    name: null
                },
                category: null,
                isLoading: false
            }
        },
        methods: {
            async addCategory() {
                
                const response = await postData('categories/', this.formData, this, true)
                this.category = {
                    name: '',
                }
                this.categories.push(response)
                localStorage.setItem('categories', JSON.stringify(this.categories));
                this.$emit('add-categories:value', {categories: this.categories, category: response})
            },
        }
    }
</script>