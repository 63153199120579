export function getDays(viewMode, context) {
    const days = new Date(context.year, context.month + 1, 0).getDate()
    let startingBlankDaysArray = []
    let endingBlankDaysArray = []
    let daysArray = []
    const startingDayOfWeek = new Date(context.year, context.month).getDay()
    const endingDayOfWeek = new Date(context.year, context.month + 1, 0).getDay()

    // starting empty cells (previous month)
    if (viewMode === 'month') {
        for (let i = 1; i <= startingDayOfWeek; i++) {
            startingBlankDaysArray.push(i)
        }

        // ending empty cells (next month)
        for (let i = 1; i < 7 - endingDayOfWeek; i++) {
            endingBlankDaysArray.push(i)
        }                    

        // current month cells
        for (let i = 1; i <= days; i++) {
            daysArray.push(new Date(context.year, context.month, i))
        }
    } else if(viewMode === 'week'){
        const startOfWeek = new Date(context.currentWeekStartDate);
        startOfWeek.setDate(context.today.getDate() - context.today.getDay()); // Start from Sunday

        for (let i = 0; i < 7; i++) {
            daysArray.push(new Date(startOfWeek));
            startOfWeek.setDate(startOfWeek.getDate() + 1);
        }
    } else if(viewMode === 'day'){
      const startOfDay = new Date(context.currentDayStartDate);
      

      for (let i = 0; i < 1; i++) {
          daysArray.push(new Date(startOfDay));
          startOfDay.setDate(startOfDay.getDate() + 1);
      }
    }

    context.startingBlankDays = startingBlankDaysArray
    context.endingBlankDays = endingBlankDaysArray
    context.daysInMonth = daysArray
};