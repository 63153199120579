<template>
    <BasePage>
      <span v-if="isLoading">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <span v-else>
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <PageTitle :title="'Notificaciones ✨'" />
            <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
                <AddMainButton :title="'Agregar notificacion'" :clickHandler="() => {modalOpen = true}" />
            </div>
        </div>
        
        <GenericTable
            :title="`Notificaciones`"
            :items="notifications"
            :columns="columns"
            :itemsPerPage="notifications.length"
          >
          <template v-slot:table-rows="{ items }">
            <tr v-for="element in items" :key="element.id">
                <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.title }}</td>
                <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                    <DeleteButton :clickHandler="() => deleteElement(element.id)" />
                </td>
            </tr>
          </template>
        </GenericTable>
        <ModalBasic id="modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Agregar notificacion" size="w-1/2">
          <NotificationsForm :notifications_="this.notifications" @handle-click:value="add($event)" />
        </ModalBasic>
      </span>
    </BasePage>
</template>
  
<script>
  import { postData } from '@/utils/postData.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { deleteData } from '@/utils/deleteData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

  import Swal from 'sweetalert2';
  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import ModalBasic from '@/components/ModalBasic.vue'
  import GenericTable from '@/components/GenericTable.vue'
  import DeleteButton from '@/components/buttons/DeleteButton.vue'
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  import NotificationsForm from '@/partials/Notifications/NotificationsForm.vue'
  
  export default {
    name: 'NotificationsPage',
    components: {
        BasePage,
        PageTitle,
        ModalBasic,
        GenericTable,
        DeleteButton,
        AddMainButton,
        NotificationsForm,
    },
    data(){
        return {
            isLoading: false,
            isDeleting: false,
            deletingId: '',
            modalOpen: false,
            notifications: [],
            columns: [
                { label: 'Titulo', key: 'title' },
                { label: 'Actions', key: 'actions' }
            ],
        }
    },
    methods: {
        async deleteElement(id){
            const response = await deleteData('notifications', id, this, 'El permiso se a eliminado exitosamente.', true, true)
            if(!response){
                return
            }
            const updated = this.notifications.filter((c) => c.id !== id);
            localStorage.setItem('notifications', JSON.stringify(updated));
            this.notifications = this.notifications.filter((c) => c.id !== id)
        },
        async add(element){
          const formData = new FormData();
          formData.append('title', element.title);
          formData.append('content', element.content);
          element.images.forEach((file) => {
            formData.append('images', file);
          });

          // Append videos
          element.videos.forEach((file) => {
            formData.append('videos', file);
          });
          const response = await postData('notifications/', formData, this, true)
          if (!response) {
              return;
          };
          Swal.fire({
              icon: 'success',
              title: 'Exito!',
              text: 'La notificacion se a creado exitosamente.',
          });
          // TODO: call to endpoint
          this.notifications.push(response)
          localStorage.setItem('notifications', JSON.stringify(this.notifications));
          this.modalOpen = false
        }
    },
    created(){
        loadFromLocalStorage('notifications', 'notifications', this);
        fetchData(`notifications`, 'notifications', 'notifications', this);
    }
  }
</script>