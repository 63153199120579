<template>
    <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">
        <div class="sm:col-span-3 mt-4" >
            <label class="block text-sm font-medium mb-1" for="name">{{ $t('labels.name') }}<span class="text-rose-500">*</span></label>
            <input :disabled="!canEdit" id="name" class="form-input w-full" type="text" placeholder="Ayuda por desempleo" v-model="formData.name" required />
        </div>
        
        <span v-if="isLoading" class="sm:col-span-3 mt-3">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
        <div v-else class="sm:col-span-3" >
            <CategoriesComponent
            :disable-inputs="!canEdit"
            :init-value="this.initCategoriesValue"
            :categories_="this.categories"
            @update:model="updateCategories"
            />
        </div>
        <div class="sm:col-span-6">
            <label class="block text-sm font-medium mb-1" for="description">Descripcion</label>
            <textarea :disabled="!canEdit" id="description" v-model="formData.description" class="form-textarea w-full px-2 py-1" rows="4" required></textarea>
        </div>
        <ExtrasProspectionAccordion :can-edit="canEdit" :products="products" :form-data_="formData" :is-loading="isLoading" @update:model="updateModel"/>
    </div>
</template>

<script>
    import CategoriesComponent from '@/partials/products/CategoriesComponent.vue';
    import ExtrasProspectionAccordion from '@/partials/prospects/create/ExtrasProspectionAccordion.vue'
    
    export default {
        name: 'FormComponent',
        components: {
            CategoriesComponent,
            ExtrasProspectionAccordion
        },
        props:{
            canEdit: {
                type: Boolean,
                default: function(){
                    return false
                }
            },
            products: Array,
            isLoading: Boolean,
            initCategoriesValue: Number,
            categories_: Array,
            formData_: {
                type: Object,
                default: function() {
                    return {
                        name: '',
                        description: '',
                        maximumAge: null,
                        maximumAgeNA: true,
                        minimumInfonavitPoints: null,
                        minimumInfonavitPointsNA: true,
                        maximumInfonavitPoints: null,
                        maximumInfonavitPointsNA: true,
                        minimumDiscountedWeeks: null,
                        minimumDiscountedWeeksNA: true,
                        maxmumDiscountedWeeks: null,
                        maxmumDiscountedWeeksNA: true,
                        maximumDaysInactive: null,
                        maximumDaysInactiveNA: true,
                        fractionMaximumQuotedWeeksInactive: null,
                        fractionMaximumQuotedWeeksInactiveNA: true,
                        maximumLastSalary: null,
                        maximumLastSalaryNA: true,
                        activeBefore1982: null,
                        canVisualizeQuotedWeeksReport: null,
                        priority: null,
                        precandidateProductId: null,
                        precandidate: false,
                        minimumAge: null,
                        minimumAgeNA: true,
                        minimumBalance: null,
                        minimumBalanceNA: true,
                        maximumBalance: null,
                        maximumBalanceNA: true,
                        canHaveCredit: null,
                        creditMustNotBeActive: null,
                        mustHaveCredit: null,
                        isActive: null,
                        minimumWeeks: null,
                        minimumWeeksNA: true,
                        maximumWeeks: null,
                        maximumWeeksNA: true,
                        isLaw73: null,
                        minimumDaysInactive: null,
                        minimumDaysInactiveNA: true,
                        category: null,
                        canHaveCredit: null,
                        excludeFromProspection: false
                    };
                }
            }
        },
        data() {
            return {
                categories: this.categories_,
                formData: this.formData_,
            };
        },
        methods: {
            updateCategories(updated) {
                this.formData.category = updated;
                this.$emit('update:model', this.formData);
            },
            updateModel(formData) {
                this.formData = formData
                this.$emit('update:model', formData);
            },
        },
    }
</script>