<template>
    <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">{{title}}</h1>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle',
        props: {
            title: String
        }
    }
</script>