<template>  
  <div class="space-y-8">
  <!-- White box -->
  <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-5 min-w-60">
    <div class="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
      <div v-for="element in this.filtersConfig" :key="element.title">
        <ExtrasAccordion class="sm:col-span-6" :title="element.title">
           <span v-for="e in element.filters" :key="e.name">
            <StringFilter v-if="e.type == 'string'" 
              :filter_="this.filters[e.name]" @update:filter="updateFilter($event, e.propname)" :label="e.label" ></StringFilter>
            <CheckboxFilter v-else-if="e.type == 'checkbox'"
              @update:filter="updateCheckbox($event, e.propname)"
              :label="e.label" :options="e.options" :model="this.filters[e.name]"/>
            <DropdownRolesFilter v-else-if="e.type == 'dropdown'"
              class="mb-2 w-full" :label="e.label" :options="e.options" :model="this.filters[e.name]" @update:model="updateSelectFilter($event, e.propname)"/>
            <SliderFilter v-else-if="e.type == 'slider'" :label="e.label"  
              @update:filter="updateFilter($event, e.propname)"
              :min="Math.min(...e.values)" :max="Math.max(...e.values)" 
              :default-values_="[Math.min(...e.values), Math.max(...e.values)]"></SliderFilter>
            <span v-else-if="e.type == 'date'">
              <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{{ e.label }}</div>
              <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, `start${e.propname}`)" :default-date_="this.filters[`start${`start${e.propname}`}`]" class="pb-2 w-full" />
              <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, `end${e.propname}`)" :default-date_="this.filters[`end${e.propname}`]" class="pb-2 w-full" />
            </span>
          </span>
        </ExtrasAccordion>
      </div>
    </div>
  </div>
</div>
            
</template>

<script>
import { ref } from 'vue';

import ExtrasAccordion from '@/components/ExtrasAccordion.vue';
import StringFilter from '@/components/filters/StringFilter.vue';
import SliderFilter from '@/components/filters/SliderFilter.vue';
import CheckboxFilter from '@/components/filters/CheckboxFilter.vue';
import DatepickerFilter from '@/components/filters/DatePickerFilter.vue';
import DropdownRolesFilter from '@/partials/users/DropdownRolesFilter.vue';

export default {
  name: 'FiltersSidebar',
  props:{
    filters_: Object,
    filterConfig_: Object,
  },
  components: {
    SliderFilter,
    StringFilter,
    CheckboxFilter,
    ExtrasAccordion,
    DatepickerFilter,
    DropdownRolesFilter,
  },
  setup() {

    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }  
  },
  data(){
    return {
      filters: this.filters_,
      filtersConfig: this.filterConfig_
    }
  },
  methods: {
    updateDateRange(value, propName) {
      const update = {value: value, propName: propName}
      this.$emit(`update:updateDateFilter`, update);
    },
    updateSelectFilter(updated, propName) {
      this.filters[propName] = { ...updated };
      const update = {value: this.filters[propName], propName}
      this.$emit(`update:selectfilters`, update);
    },
    updateFilter(value, propName) {
      const update = {value: value, propName: propName}
      this.$emit(`update:filters`, update);
    },
    updateCheckbox(option, propName) {
      const update = {value: option, propName: propName}
      this.$emit('update:checkboxfilters', update);
    },
  },
}
</script>