<template>
  <div class="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 min-h-80">
    <header class="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
      <div class="flex justify-between">
        <h2 class="font-semibold text-slate-800 dark:text-slate-100 content-center">{{title}}</h2>
      </div>
    </header>
    <div class="p-3">

      <div v-for="(item, index) in this.feesList" :key="index">
          <!-- Item -->
          <li class="flex px-2">
            
            <div class="grow flex items-center border-b border-slate-100 dark:border-slate-700 text-sm py-2">
              <div class="grow flex justify-between">
                <div class="self-center">{{item}}</div>
              </div>
            </div>
          </li>
        
        
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ErrorsTable',
  components: {
    
  },
  props: {
    isLoading: Boolean,
    title: String,
    list_: {
      type: Array,
      default: function() {
        return [
          'Error 1',
          'Error 2'
        ];
      }
    }
  },
  data(){
    return {
      feeClassIndex: null,
      feeAmountIndex: null,
      feeClassName: '',
      feeAmountName: '',
      feeAmount: null,
      feeVariable: false,
      feesList: this.list_
    }
  },
  methods: {
    addFeeClass(){
      this.feesList.push({
        'name': this.feeClassName,
        'fees': []
      })
      this.feeClassName = '';
      this.feeClassOpen = false;
      this.updateModel(this.feesList);
    },
    removeFeeClass(index){
      this.feesList.splice(index, 1);
      this.updateModel(this.feesList);
    },
    addFeeAmount(){
      this.feesList[this.feeAmountIndex].fees.push({'name': this.feeAmountName, 'amount': this.feeAmount, 'variable': this.feeVariable})
      this.feeAmountIndex = null;
      this.feeAmountName = '';
      this.feeAmount = null;
      this.feeVariable = false;
      this.feeAmountOpen = false;
      this.updateModel(this.feesList);
    },
    removeFeeAmount(index, subIndex){
      this.feesList[index].fees.splice(subIndex, 1);
      this.updateModel(this.feesList);
    },
    updateModel(option) {
      this.$emit('update:model', option);
    },
  }
}
</script>