// src/utils/apiUtils.js
import api from '../interceptors/axiosInterceptos'; 
import Swal from 'sweetalert2';

export async function deleteData(endpoint, id, context, successMessage, loading=true, notification=true) {
  try {
    context.isDeleting = true;
    context.deletingId = id;
    if (loading) {
      context.isLoading = true;
    }
    const token = localStorage.getItem('token');
    const headers = { 
      "authorization": `Bearer ${token}`,
    };
    const response = await api.delete(`${process.env.API_URL}/${endpoint}/${id}`, { headers });

    context.isDeleting = false;
    context.deletingId = '';
    if (loading) {
      context.isLoading = false;
    }
    if(response.status == 200){
      if (notification) {
        Swal.fire({
            icon: 'success',
            title: 'Exito!',
            text: successMessage,
        });
      }
      return true;
    }
    return false;
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response?.data?.message ?? error.message
    });
    context.isLoading = false;
    return false
  }
}
