import { getProperty } from '@/utils/getProperty.js'
export function getUniqueValuesFilter(elements, propertyName) {
    const values = elements.map(obj => getProperty(obj, propertyName));
    const uniqueValues = [...new Set(values)];
    const filter = {};
    let uniqueList =[]
    uniqueValues.forEach(value => {
        filter[value === "" ? "Vacio" : (value === null) ? "Nulo" : value] = true;
    });
    uniqueValues.forEach(value => {
        uniqueList.push(value === "" ? "Vacio" : (value === null) ? "Nulo" : value);
    });
    return {uniqueValues: uniqueList, filter};
};