<!-- UserTable.vue -->
<template>
    <!-- Cards -->
    <div class="grid grid-cols-12 gap-6">
        <SourcesTilesCard
            v-for="item in sources"
            :key="item.id"
            :item="item"
            :can-delete="canDelete"
            @delete-f="deleteF"
            :is-deleting="isDeleting" :deleting-id="deletingId" :isLoading="isLoading"
        />
        
    </div>
</template>
    
<script>
    import { deleteData } from '@/utils/deleteData.js';
    import SourcesTilesCard from './SourcesTilesCard.vue';
    

    export default {
      name: "SourcesTilesList",
      emits: ["delete-f"], 
      props: {
        sources: Array,
        canDelete: {
          type: Boolean,
          default: function(){
            return false
          }
        }
      },
      data() {
        return {
          isLoading: false,
          isDeleting: false,
          deletingId: ''
        };
      },
      components: {
        SourcesTilesCard,
      },
      methods: {
        async deleteF(id) {
          this.isDeleting = true;
          this.deletingId = id;
          const response = await deleteData('sources', id, this, 'El promotor se a eliminado exitosamente.', true, true)
          this.isDeleting = false;
          this.deletingId = '';
          if(!response){
            return
          }
          this.$emit('delete-f', id);
        },
      },
    };
</script>
    