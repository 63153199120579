<template>
  <ModalBasic id="feedback-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Agregar Promotor" size="w-1/3">
      <!-- Modal content -->
      <form @submit.prevent="add">
      <div class="px-5 py-4">
        <div class="space-y-3">
          <div>
            <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
            <input id="name" v-model="source.name" class="form-input w-full px-2 py-1" type="text" required />
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
        <div class="flex flex-wrap justify-end space-x-2">
          <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {modalOpen = false}" />
        </div>
      </div>
    </form>
</ModalBasic>
    <BasePage>
      <div class="sm:flex sm:justify-between sm:items-center mb-8">

        <!-- Left: Title -->
        <PageTitle :title="`Promotores ✨`" />

        <!-- Right: Actions  -->
        <div v-if="showAdd" class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        <!-- Search form -->
            
            <!-- Add member button -->
            <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white" @click.stop="modalOpen = true">
                <svg class="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span class="hidden xs:block ml-2" aria-controls="feedback-modal" >Agregar Promotor</span>
            </button>
        </div>            

        </div>
        <div class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
        <div class="w-full">
            <span v-if="!isLoading">
                <SourcesTilesList @delete-f="deleteF" :sources="sources" :can-delete="showDelete"></SourcesTilesList>
            </span>
            <span v-else>
                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
        </div>
      </div>
    </BasePage>
  </template>
  
  <script>
  import { postData } from '@/utils/postData.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import {hasSpecificPermission} from '@/utils/hasSpecificPermission.js';
  
  import Swal from 'sweetalert2';
  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import ModalBasic from '@/components/ModalBasic.vue';
  import SourcesTilesList from '@/partials/sources/SourcesTilesList.vue';
  import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
  
  export default {
    name: 'SourcesPage',
    components: {
      BasePage,
      PageTitle,
      ModalBasic,
      SourcesTilesList,
      SubmitMainButton,
    },
    data(){
      return {
        modalOpen: false,
        showAdd: false,
        showDelete: false,
        sources: [],
        isLoading: false,
        isDeleting: false,
        deletingId: '',
        source: {
            name: '',
        },
      }
    },
    methods:{
      async getSources() {
        this.sources = await fetchData(`sources`, 'sources', 'sources', this);
      },
      async add(){
        const response = await postData('sources/', this.source, this, true)
        if (!response) {
            return;
        };
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'El promotor se a creado exitosamente.',
        });
        this.source = {
          name: ''
        }
        this.sources.push(response)
        localStorage.setItem('sources', JSON.stringify(this.sources));
      },
      async deleteF(id){
        const updated = this.sources.filter((source) => source.id !== id);
        localStorage.setItem('sources', JSON.stringify(updated));
        this.sources = this.sources.filter((source) => source.id !== id)
      },
    },
    async created() {
      loadFromLocalStorage('sources', 'sources', this);
      this.getSources();
      hasSpecificPermission('showAdd', 'add', ["add_sources"], this)
      hasSpecificPermission('showDelete', 'delete', ["delete_sources"], this)
    }
  }
  </script>