import mime from 'mime';
import Swal from 'sweetalert2';

import api from '@/interceptors/axiosInterceptos'; 

export function getFileExtension(filename) {
    return `.${filename.split('.').pop()}`;
};
export async function save(document, context) {
      
    if (!context.file) {
      Swal.fire({
        icon: 'error',
        title: 'Archivo faltante',
        text: 'Por favor seleccione uno',
      });
      return; // No file selected
    }
    context.isLoading = true;
    const formData = new FormData();
    formData.append('file', context.file);
    formData.append('name', document.name);
    formData.append('documentExtension', getFileExtension(context.file.name));

    context.$emit('save-document', {documentId: document.id, body: formData, name:document.name, documentExtension: getFileExtension(context.file.name)});
    context.selectedFileName = null;
    context.file = null;
    context.isLoading = false;
    context.modalUploadOpen = false
};

export async function downloadDocument(d, context) {    
    context.isLoading = true;
    await context.getD(d);
    const link = document.createElement('a');
    // Set the href to the URL of the PDF
    link.href = context.documentUrl;
    // Set the download attribute to suggest a filename
    link.download = `${d.name}${d.documentExtension}`;
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
    context.isLoading = false;
};

export function handleMultipleFileChange(event, context, validFormats, maxFiles, type) {
  const files = Array.from(event.target.files);

  if (files.length > maxFiles) {
      Swal.fire({
          icon: 'error',
          title: 'Demasiados archivos seleccionados',
          text: `Por favor seleccione un máximo de ${maxFiles} archivos.`,
      });
      return;
  }

  const selectedFiles = [];
  for (const file of files) {
      if (!validFormats.includes(getFileExtension(file.name))) {
          Swal.fire({
              icon: 'error',
              title: 'Tipo de archivo invalido',
              text: `Por favor seleccione un archivo con una de las siguientes extensiones ${validFormats.join(', ')}`,
          });
          return;
      }

      const maxSize = 100 * 1024 * 1024; // 5MB per file
      if (file.size > maxSize) {
          Swal.fire({
              icon: 'error',
              title: 'Archivo demasiado grande',
              text: 'Por favor seleccione un archivo de menos de 100MB',
          });
          return;
      }

      selectedFiles.push(file);
  }

  if (type === 'images') {
      context.selectedImages = selectedFiles;
      context.$refs.imagefileInput.value = '';
  } else if (type === 'videos') {
    context.selectedVideos = selectedFiles;
    context.$refs.videofileInput.value = '';
  }
}


export function handleFileChange(event, context, validType) {
    // Handle file change event and store the selected file
    const file = event.target.files[0];
    if (!file) return; // No file selected
    if (!validType.includes(getFileExtension(file.name))) {
      context.file = null;
      context.selectedFileName = null;
      Swal.fire({
        icon: 'error',
        title: 'Tipo de archivo invalido',
        text: `Por favor seleccione un archivo con una las siguientes extensiones ${validType}`,
      });
      return;
    }
    // Check file size (in bytes)
    const maxSize = 100 * 1024 * 1024; // 1MB
    if (file.size > maxSize) {
      Swal.fire({
        icon: 'error',
        title: 'Archivo demasiado grande',
        text: 'Por favor seleccione un archivo de menos de 100MB',
      });
      return;
    }
    context.file = file;
    context.selectedFileName = context.file.name;
    // You can perform validation on the file if needed
    // For example, check file type, size, etc.
};

export async function getDocument(document, context){
    const token = localStorage.getItem('token');
    const body = {
      path: `${document.clientId}/${document.id}${document.documentExtension}`
    }
    const formData = new FormData();
    formData.append('path', `${document.clientId}/${document.id}${document.documentExtension}`);
    
    try {
      const response = await api.post(`${process.env.API_URL}/documents/stream/${document.clientId}%2F${document.id}${document.documentExtension}`, formData,{
      
        headers: {
          'Content-Type': 'multipart/form-data',
          "authorization": `Bearer ${token}`,
        },
        responseType: 'blob'
      })
      context.documentLoadingSuccess = true;
      context.documentUrl = URL.createObjectURL(new Blob([response.data], { type: mime.getType(document.documentExtension) }));
    } catch (error) {
      console.error('Error fetching document:', error);
      context.documentLoadingSuccess = false;
      context.error = true;
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.response.statusText,
        
      })
    }  
      
};

export function previewDocument(document, context) {    
    context.isLoading = true;
    getDocument(document, context)
    context.showData = true;
    context.isLoading = false;
};
