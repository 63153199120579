<template>
  <BasePage>
    <span v-if="isLoading">
      <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
    </span>
    <div v-else class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="sm:flex sm:justify-between sm:items-center mb-8">
        <!-- Left: Title -->
        <PageTitle :title="`TODO's ✨`" />
      </div>
      <div class="mb-4 border-b border-slate-200 dark:border-slate-700">
      </div>          

      <div class="w-full grid grid-cols-12 gap-x-4 gap-y-8">
        <div v-if="showFilters" class="col-span-full sm:col-span-12 xl:col-span-3">
        <FiltersSidebar
          :key="componentKey"
          :filter-config_="this.filtersConfig"
          :filters_="filters"
          @update:checkboxfilters="updateCheckBoxFilter($event, this)"
          @update:filters="updateFilter($event, this)"
          @update:updateDateFilter="updateDateFilter($event, this)"
          @update:selectfilters="updateCheckBoxFilter($event, this)"
            />
        </div>
        <TodosTable :key="componentKey" :todos="this.todos" :filters="this.filters"/>
      </div>

    </div>
  </BasePage>
</template>

<script>
import { fetchData } from '@/utils/fetchData.js';
import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
import { getUniqueValuesFilter } from '@/utils/getUniqueValuesFilter.js';

import BasePage from '@/components/BasePage.vue';
import PageTitle from '@/components/PageTitle.vue';
import TodosTable from '@/partials/todos/TodosTable.vue'
import FiltersSidebar from '@/components/FiltersSidebar.vue';

export default {
  name: 'TasksKanban',
  components: {
    BasePage,
    PageTitle,
    TodosTable,
    FiltersSidebar,
  },
  data(){
    return {
      todos: [],
      users: [],
      componentKey: 0,
      showFilters: false,
      isLoading: false,
      filters: {
        name: '',
        startcreatedAt: null,
        endcreatedAt: null,
        balance: [],
        selectedAppointmentStatus: {},
        selectedAgent: {},
        selectedTypes: {},
      },
      filtersConfig: [
        {title: 'General', filters: [
          {name: 'name', label: 'Nombre', propname: 'name', type: 'string', options: null, values: null},
          {name: 'createdAt', label: 'Fecha de Creacion', propname: 'createdAt', type: 'date', options: null, values: null}
        ]},
        // {title: 'Progreso', filters: [
        //   {name: 'selectedAppointmentStatus', label: 'Cita', propname: 'selectedAppointmentStatus', type: 'checkbox', options: ['TODO'], values: null}
        // ]},
        {title: 'Producto/Vendedor', filters: [
          {name: 'selectedAgent', label: 'Vendedor', propname: 'selectedAgent', type: 'dropdown', options: [], values: null},
          {name: 'selectedProducts', label: 'Producto', propname: 'selectedProducts', type: 'dropdown', options: [], values: null}
        ]},
        {title: 'Tipo', filters: [
          {name: 'selectedTypes', label: 'Tipo', propname: 'selectedTypes', type: 'dropdown', options: [], values: null},
        ]},
        // {title: 'Infonavit', filters: [
        //   {name: 'balance', label: 'Saldo Cuenta', propname: 'balance', type: 'slider', values: [0]}
        // ]}, 
      ]
    }
  },
  methods: {
    updateFilter(updated, context){
      context.filters[updated.propName] = updated.value;
    },
    updateDateFilter(updated, context){
      context.filters[updated.propName] = updated.value !==  null ? updated.value['0'] : null;
    },
    updateCheckBoxFilter(updated, context){
      context.filters[updated.propName] = { ...updated.value };
    },
    async getTodos() {
        this.users = await fetchData(`users`, 'users', 'users', this);
        const user = JSON.parse(localStorage.getItem('me')) || {};
        await fetchData(`todos/user/${user.id}`, 'todos', 'todos', this);
        this.setFilters();
        this.componentKey++
        return
    },
    setFilters(){
      const { uniqueValues: uniqueProducts, filter: selectedProducts } = getUniqueValuesFilter(this.todos, 'clientproduct.name');
      this.filtersConfig[1].filters[1].options = uniqueProducts
      this.filters.selectedProducts = selectedProducts;
      const { uniqueValues: uniqueTypes, filter: selectedTypes } = getUniqueValuesFilter(this.todos, 'type');
      this.filtersConfig[2].filters[0].options = uniqueTypes
      this.filters.selectedTypes = selectedTypes;
      const usersOptions = this.users.map(user => user.username)
      this.filtersConfig[1].filters[0].options = usersOptions
      for (const option in usersOptions) {
        this.filters.selectedAgent[usersOptions[option]] = true;
      } 
    }
  },
  async created() {
    hasSpecificPermission('showFilters', 'page-filter', ["view_todos_filter"], this)
    loadFromLocalStorage('todos', 'todos', this);
    loadFromLocalStorage('users', 'users', this);
    this.setFilters();
    this.getTodos();
  },
}
</script>