<template>
    <form @submit.prevent="handleSurvey">
      <div class="px-5 py-4">
        <div class="space-y-3">
            <div>
                <label class="block text-sm font-medium mb-1" for="name">Pregunta <span class="text-rose-500">*</span></label>
                <input id="name" v-model="formData.question" class="form-input w-full px-2 py-1" type="text" required />
            </div>
            <DropdownFull :dropdownOptions="typeOptions" :model="this.formData.type" @update:model="updateType" :init-value="typeOptions.findIndex(c => c.slug == this.formData.type)"/>
            <div v-if="formData.type == 'options'">
                <label class="block text-sm font-medium mb-1" for="description">Opciones</label>
                <textarea id="description" v-model="formData.options" class="form-textarea w-full px-2 py-1" placeholder="Opciones de la siguiente manera ['a', 'b', 'c']" rows="4" ></textarea>
            </div>
            <div>
                <label class="block text-sm font-medium mb-1" for="name">Pseudonimo <span class="text-rose-500">*</span></label>
                <input id="pseudonym" v-model="formData.pseudonym" class="form-input w-full px-2 py-1" type="text" required />
            </div>
            <div class="form-check">
              <input class="form-checkbox" type="checkbox" value="" id="requestDuringSignature" v-model="formData.requestDuringSignature">
              <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">Solicitar durante el proceso de firma</span>
            </div>
            <label class="block text-sm font-medium mb-1">Requerido</label>
            <DropdownFull :dropdownOptions="[
                  { id: 0, name: 'No', value: false },
                  { id: 1, name: 'Si', value: true },
            ]" :model="this.formData.required" @update:model="updateQuestionRequired"/>
            <div class="pb-5" ></div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div class="flex flex-wrap justify-end space-x-2">
              <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="closeModal" />
          </div>
      </div>
    </form>
        
    
</template>

<script>
    import DropdownFull from '@/components/DropdownFull.vue';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    export default {
        name: 'SurveyForm',
        props: {
            isLoading: Boolean,
            formData_: {
                type: Object,
                default: function(){
                    return {
                        question: null,
                        options: null,
                        required: false,
                        type: 'text',
                        pseudonym: null,
                        requestDuringSignature: true,
                    }
                }
            }
        },
        components: {
            DropdownFull,
            SubmitMainButton,
        },
        data(){
            return {
                formData: this.formData_,
                typeOptions: [
                    { id: 0, name: 'Texto', slug: 'text' },
                    { id: 1, name: 'Numero', slug: 'number'},
                    { id: 2, name: 'Decimal', slug: 'float' },
                    { id: 2, name: 'Checkbox', slug: 'checkbox' },
                    { id: 2, name: 'Opciones', slug: 'options' },
                ]
            }
        },
        methods: {
            handleSurvey(){
                this.$emit('handle-click:value', this.formData)
            },
            closeModal(){
                this.formData = {
                    question: null,
                    options: null,
                    required: false,
                    type: 'text',
                    pseudonym: null,
                    requestDuringSignature: true,
                } 
                this.$emit('close-modal')
            },
            updateQuestionRequired(updated) {
                this.formData.required = updated.value;
            },
            updateType(updated) {
                this.formData.type = updated.slug;
            },
        }
    }
</script>