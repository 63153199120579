
import api from '../src/interceptors/axiosInterceptos'; 

export async function validateSession() {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(`${process.env.API_URL}/auth/validate-session/${token}`);
    localStorage.setItem('token', response.data.token)
    localStorage.setItem('me', JSON.stringify(response.data.user))
    return response.data.valid;
  } catch (error) {
    console.error('Error validating session:', error);
    return false;
  }
}

export async function logoutUser() {
  try {
    const email = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    const headers = { 
      "authorization": `Bearer ${token}`,
    };
    localStorage.clear();
    const response = await api.post(`${process.env.API_URL}/auth/logout/${email}`, {}, { headers });
  } catch (error) {
    console.error('Error logging out user:', error);
  }
}