<template>
    <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header class="px-5 py-4">
        <h2 class="font-semibold text-slate-800 dark:text-slate-100">{{ title }} <span class="text-slate-400 dark:text-slate-500 font-medium">{{ items?.length }}</span></h2>
      </header>
      <div>
        <!-- Table -->
        <div class="overflow-x-auto">
          <table class="table-auto w-full dark:text-slate-300">
            <!-- Table header -->
            <thead class="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th v-for="(column, index) in columns" :key="index" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div class="font-semibold text-left">{{ column.label }}</div>
                </th>
              </tr>
            </thead>
            <!-- Table body -->
            <tbody class="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              <slot name="table-rows" :items="pagedItems"></slot>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Pagination -->
      <div v-if="showPagination" class="mt-8">
        <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between p-4">
          <nav class="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
            <ul class="flex justify-center">
              <li class="ml-3 first:ml-0">
                <button 
                  @click="prevPage" :disabled="currentPage === 1" 
                  class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500">
                  &lt;- Previous
                </button>
              </li>
              <li class="ml-3 first:ml-0">
                <button 
                  @click="nextPage" :disabled="currentPage === totalPages" 
                  class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500">
                  Next -&gt;
                </button>
              </li>
            </ul>
          </nav>
          <div class="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left">
            Mostrando {{ currentPage }} de {{ totalPages }} páginas
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'GenericTable',
    props: {
      title: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      columns: {
        type: Array,
        required: true, // [{ label: 'Nombre', key: 'name' }, { label: 'Descripción', key: 'description' }]
      },
      itemsPerPage: {
        type: Number,
        default: 10,
      },
      showPagination: {
        type: Boolean,
        default: function(){
          return false
        }
      }
    },
    data() {
      return {
        currentPage: 1,
        isLoading: false,
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.items.length / this.itemsPerPage);
      },
      pagedItems() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.items.slice(start, end);
      },
    },
    methods: {
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.totalPages) this.currentPage++;
      },
    },
  };
</script>
  