<template>
  <router-view />
</template>

<script>
import { onBeforeUnmount } from 'vue';
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  setup() {
    onBeforeUnmount(() => {
      if (this.$root.$ws) {
        this.$root.$ws.close();
      }
    });
  }
};
</script>

<style>
</style>
