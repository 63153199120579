<template>
    <TasksGroups v-for="element in boardElements" :key="element.title" :title="element.title">
        <TodosTableItem
            v-for="e in element.list"
            :key="e.id"
            :element="{todo: e}"
        />
    </TasksGroups>
</template>
<script>
    import TasksGroups from '@/partials/tasks/TasksGroups.vue'
    import TodosTableItem from '@/partials/prospects/detail/todos/TodosTableItem.vue';

    import { applyDateFilters, applySelectFilters, applyTextFilters } from '@/utils/filters.js';
    export default {
        name: 'TodosTable',
        components: {
            TasksGroups,
            TodosTableItem,
        },
        props: {
            todos: Array,
            filters: Object
        },
        data(){
            return {
                users: []
            }
        },
        computed:{
            boardElements() {
                return [
                    {title: "Pendiente 📝", list: this.filterValues('TODO')},
                    {title: "En Progreso ✌️", list: this.filterValues('INPROGRESS')},
                    {title: "Completado 🎉", list: this.filterValues('DONE')},
                ];
            }
        },
        methods: {
            filterValues(status){
                let filtered = this.todos;
                filtered = applyDateFilters(filtered, ['createdAt'], this);
                filtered = applySelectFilters(filtered, 'selectedAgent', 'user.username', this);
                filtered = applySelectFilters(filtered, 'selectedProducts', 'clientproduct.name', this);
                filtered = applySelectFilters(filtered, 'selectedTypes', 'type', this);
                filtered = applyTextFilters(filtered, ['name'], this);
                return filtered.filter((element) => { return element.status == status }).sort((a, b) => {
                    const dateA = a?.dueDate ? new Date(a.dueDate) : null;
                    const dateB = b?.dueDate ? new Date(b.dueDate) : null;

                    if (dateA === null) return 1; // place items with null dueDate at the end
                    if (dateB === null) return -1; // place items with null dueDate at the end
                    if (dateA === dateB) return 0;

                    return dateA - dateB;
                })
                
            },
            
        },
    }
</script>