<template>
    <label class="block text-sm font-medium mb-1">
        {{ label }}:
    </label>
    <div class="flex w-full">
        
        <input v-if="this.list?.length < 1" id="email" class="form-input w-full" type="text" :placeholder="sublabel" :disabled="true" />
        
        <DropdownFull v-else 
        :propname="propname"
        :dropdownOptions="this.list" 
        :init-value="this.initValue"
        :model="this.model" @update:model="updateValue" />

        <div class="pr-2"></div>
        <button v-if="canAdd" class="text-white-500 hover:text-white-600 rounded-full">

            <svg @click.stop.prevent="modalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
        </button>
        <ModalBasic id="feedback-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" :title="modaltitle">
            <!-- Modal content -->
            <form @submit.prevent="add">
            <div class="px-5 py-4">
                <div class="space-y-3">
                    <div>
                        <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                        <input id="name" v-model="this.formData.name" class="form-input w-full px-2 py-1" type="text" required />
                    </div>
                </div>
            </div>
            <!-- Modal footer -->
            <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
                <div class="flex flex-wrap justify-end space-x-2">
                    <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {modalOpen = false}" />
                </div>
            </div>
            </form>
        </ModalBasic>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import ModalBasic from '@/components/ModalBasic.vue';
    import DropdownFull from '@/components/DropdownFull.vue';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    
    export default {
        name: 'DropdDownWithFormModal',
        props: {
            propname: {
                type: String,
                default: function(){
                    return 'name'
                }
            },
            modaltitle: {
                type: String,
                default: function(){
                    return 'Agregar Categoria'
                }
            },
            sublabel: {
                type: String,
                default: function(){
                    return 'Crea al menos una categoria';
                }
            },
            canAdd: {
                type: Boolean,
                default: function(){
                    return false;
                }
            },
            label: String,
            list_: Array,
            initValue: Number
        },
        components: {
            ModalBasic,
            DropdownFull,
            SubmitMainButton,
        },
        data(){
            return {
                list: this.list_,
                model: null,
                formData: {
                    name: null
                },
                isLoading: false
            }
        },
        setup() {
            const modalOpen = ref(false)

            return {
                modalOpen
            }  
        },
        methods: {
            updateValue(updated) {
                this.$emit('update:model', updated);
            },
            async add() {
                this.$emit('add');
            },
        }
    }
</script>