<template>
    <ModalBasic
        id="form-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Crear Evento">
        <form @submit.prevent="addEvent">
            <div class="px-5 py-4">
                <div class="space-y-3">
                    <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">
                        <div class="sm:col-span-6" >
                            <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                            <input id="name" :disabled="this.blockInputs" v-model="formData.name" class="form-input w-full px-2 py-1" type="text" required />
                        </div>
                        <div class="sm:col-span-6" >
                            <label class="block text-sm font-medium mb-1" for="description">Descripcion <span class="text-rose-500">*</span></label>
                            <textarea id="description" :disabled="this.blockInputs" v-model="formData.description" class="form-textarea w-full px-2 py-1" rows="4"></textarea>
                        </div>
                        <!-- Start Date Input -->
                        <div class="sm:col-span-3" >
                            <label class="block text-sm font-medium mb-1" for="startDate">Start Date <span class="text-rose-500">*</span></label>
                            <input id="startDate" :disabled="this.blockInputs" v-model="formData.eventDate" class="form-input w-full px-2 py-1" type="date" required />
                        </div>
                        
                        
                        <!-- Start Hour Input -->
                        <div class="sm:col-span-3" >
                            <label class="block text-sm font-medium mb-1" for="startHour">Start Hour <span class="text-rose-500">*</span></label>
                            <input id="startHour" :disabled="this.blockInputs" v-model="this.formData.eventHour" class="form-input w-full px-2 py-1" type="time" required />
                        </div>

                        <!-- End Date Input -->
                        <div class="sm:col-span-3" >
                            <label class="block text-sm font-medium mb-1" for="endDate">End Date <span class="text-rose-500">*</span></label>
                            <input id="endDate" :disabled="this.blockInputs" v-model="formData.eventEndDate" class="form-input w-full px-2 py-1" type="date" required />
                        </div>
                        
                        <!-- End Hour Input -->
                        <div class="sm:col-span-3" >
                            <label class="block text-sm font-medium mb-1" for="endHour">End Hour <span class="text-rose-500">*</span></label>
                            <input id="endHour" :disabled="this.blockInputs" v-model="formData.eventEndHour" class="form-input w-full px-2 py-1" type="time" required />
                        </div>
                        <div class="sm:col-span-6" v-if="validationError">
                            <p class="text-red-500">{{ validationError }}</p>
                        </div>
                        <div v-if="!this.blockInputs" class="sm:col-span-6 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg">
                            <multiselect
                            v-model="this.formData.selectedUsers"
                            :options="this.usersOptions"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Invitar usuarios"
                            label="username"
                            track-by="username"
                            :custom-label="customLabel"
                            >
                                <template #selection="{ values }">
                                    <div  class="flex flex-wrap">
                                        <span class="bg-indigo-500 text-white px-2 py-1 rounded mr-2 mb-2" 
                                        v-for="(option, index) in values" :key="index">{{ option.username }}</span>                                    
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div> 
                </div>
            </div>
            <!-- Modal footer -->
            <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
                <div class="flex flex-wrap justify-end space-x-2">
                    <button class="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300" @click.stop.prevent="modalOpen = false">Cancelar</button>
                    <button class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white" type="submit">
                        <span v-if="!isLoading">{{ $t('buttons.save') }}</span>
                        <span v-else>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </ModalBasic>
    <div class="bg-white dark:bg-slate-800 rounded-sm shadow overflow-hidden">
  
        <!-- Days of the week -->
        <div v-if="this.viewMode == 'month'" class="grid grid-cols-7 gap-px border-b border-slate-200 dark:border-slate-700">
            <div class="px-1 py-3" v-for="(day, index) in dayNames" :key="index">
            <div class="capitalize text-slate-500 text-sm font-medium text-center lg:hidden">{{day.substring(0,3)}}</div>
            <div class="capitalize text-slate-500 dark:text-slate-400 text-sm font-medium text-center hidden lg:block">{{day}}</div>
            </div>
        </div>
        <div v-else-if="this.viewMode == 'week'" class="grid grid-cols-8 gap-px border-b border-slate-200 dark:border-slate-700"> 
            <div class="px-1 py-3">
                <div class="capitalize text-slate-500 dark:text-slate-400 text-sm font-medium text-center hidden block">Hora</div>
            </div>
            <div class="px-1 py-3" v-for="(day, index) in dayNames" :key="index">
            <div class="capitalize text-slate-500 text-sm font-medium text-center lg:hidden">{{day.substring(0,3)}}</div>
            <div class="capitalize text-slate-500 dark:text-slate-400 text-sm font-medium text-center hidden lg:block">{{day}}</div>
            <div class="capitalize text-slate-500 text-sm font-medium text-center">{{daysInMonth[index]?.getDate()}}</div>
            </div>
        </div>
        <div v-else class="grid grid-cols-1 gap-px border-b border-slate-200 dark:border-slate-700">
            <div class="px-1 py-3">
            <div class="capitalize text-slate-500 text-sm font-medium text-center lg:hidden">{{dayFullName(this.currentDayStartDate).substring(0,3)}} {{ this.currentDayStartDate.getDate() }}</div>
            <div class="capitalize text-slate-500 dark:text-slate-400 text-sm font-medium text-center hidden lg:block">{{dayFullName(this.currentDayStartDate)}} {{ this.currentDayStartDate.getDate()}}</div>
            </div>
        </div>

        <!-- Day cells -->
        <div v-if="this.viewMode == 'month'" class="grid grid-cols-7 gap-px bg-slate-200 dark:bg-slate-700">
            <!-- Diagonal stripes pattern -->
            <svg class="sr-only">
            <defs>
                <pattern id="stripes" patternUnits="userSpaceOnUse" width="5" height="5" patternTransform="rotate(135)">
                <line class="stroke-current text-slate-200 dark:text-slate-700 opacity-50" x1="0" y="0" x2="0" y2="5" stroke-width="2" />
                </pattern>
            </defs>
            </svg>
            <!-- Empty cells (previous month) -->
            <div class="bg-slate-50 dark:bg-slate-800 h-20 sm:h-28" :class="small ? 'lg:h-20' : 'lg:h-36'" v-for="(blankday, index) in startingBlankDays" :key="index">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                <rect width="100%" height="100%" fill="url(#stripes)" />
            </svg>
            </div>
            <!-- Days of the current month -->
            <div class="relative bg-white dark:bg-slate-800 h-20 sm:h-28 overflow-hidden" :class="small ? 'lg:h-20' : 'lg:h-36'" v-for="(day, index) in daysInMonth" :key="index">
            <div class="h-full flex flex-col justify-between" @click.prevent.stop="modalOpen = true, this.handleClick(null, day)">
                <!-- Events -->
                <div class="grow flex flex-col relative p-0.5 sm:p-1.5 overflow-hidden">
                <button
                    class="relative w-full text-left mb-1"
                    v-for="(event, index) in getEvents(day)"
                    :key="index"
                    @click.prevent.stop="this.allowEdit ? (modalOpen = true, this.handleClick(null, day, event)) : null"
                >
                    <div class="px-2 py-0.5 rounded overflow-hidden" :class="eventColor(event.type)">
                    <!-- Event name -->
                    <div class="text-xs font-semibold truncate">{{event.name}}</div>
                    <!-- Event time -->
                    <div class="text-xs uppercase truncate hidden sm:block">
                        <!-- Start date -->
                        <span v-if="event.eventDate">{{new Date(event.eventDate).toLocaleTimeString([], {hour12: true, hour: 'numeric', minute:'numeric'})}}</span>
                        <!-- End date -->
                        <span v-if="event.eventEndDate">
                        - <span>{{new Date(event.eventEndDate).toLocaleTimeString([], {hour12: true, hour: 'numeric', minute:'numeric'})}}</span>
                        </span>
                    </div>
                    </div>
                </button>
                <div class="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white dark:from-slate-800 to-transparent pointer-events-none" aria-hidden="true"></div>
                </div>
                <!-- Cell footer -->
                <div class="flex justify-between items-center p-0.5 sm:p-1.5">
                <!-- More button (if more than 2 events) -->
                <button
                    v-if="getEvents(day).length > 2"
                    class="text-xs text-slate-500 dark:text-slate-300 font-medium whitespace-nowrap text-center sm:py-0.5 px-0.5 sm:px-2 border border-slate-200 dark:border-slate-700 rounded"
                >
                    <span class="md:hidden">+</span><span>{{getEvents(day).length - 2}}</span> <span class="hidden md:inline">more</span>
                </button>
                <!-- Day number -->
                <button class="inline-flex ml-auto w-6 h-6 items-center justify-center text-xs sm:text-sm font-medium text-center rounded-full hover:bg-indigo-100 dark:hover:bg-slate-600" :class="isToday(day.getDate()) ?  'border border-indigo-500 text-indigo-500 dark:text-slate-300' : 'hover:bg-indigo-100 dark:hover:bg-slate-600'">
                    {{day.getDate()}}
                </button>
                </div>
            </div>
            </div>
            <!-- Empty cells (next month) -->
            <div class="bg-slate-50 dark:bg-slate-800 h-20 sm:h-28" :class="small ? 'lg:h-20' : 'lg:h-36'" v-for="(blankday, index) in endingBlankDays" :key="index">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                <rect width="100%" height="100%" fill="url(#stripes)" />
            </svg>
            </div>
        </div>
        <div v-else-if="this.viewMode == 'week'" class="grid grid-cols-8 gap-px bg-slate-200 dark:bg-slate-700">
            <!-- Hour Labels Column -->
            <div class="col-span-1 grid grid-rows-24 gap-px bg-slate-200 dark:bg-slate-700">
                <div v-for="hour in hours" :key="hour" class="bg-slate-50 dark:bg-slate-800 h-12 sm:h-12 lg:h-12 flex items-center justify-center">
                    <span class="text-xs sm:text-sm dark:text-slate-300 font-medium">{{ hour }}</span>
                </div>
            </div>

            <!-- Days Columns -->
            <div class="col-span-7 grid grid-cols-7 gap-px">
                <div v-for="(day, dayIndex) in daysInMonth" :key="dayIndex" class="relative h-full bg-white dark:bg-slate-800">
                    <!-- Hour Rows -->
                    <div class="absolute inset-0 grid grid-rows-24 gap-px z-10">
                        <div v-for="(hour, hourIndex) in hours" :key="hour" class="border-t border-gray-200 dark:border-gray-700" >
                            <div 
                            class="h-full" 
                            @click.prevent.stop="this.checkEvent(day, hour, hourIndex) 
                                                 ? (modalOpen = true)
                                                 : null">
                            </div>
                        </div>
                    </div>

                    <!-- Events -->
                    <div class="absolute inset-0" >
                        <div v-for="(event, index) in getEvents(day)" :key="index"
                            :style="{ 
                                top: `${(new Date(event.eventDate).getHours() + new Date(event.eventDate).getMinutes() / 60) * 100 / 24}%`, 
                                height: event.eventEndDate ? `${((new Date(event.eventEndDate).getHours() + new Date(event.eventEndDate).getMinutes() / 60) - (new Date(event.eventDate).getHours() + new Date(event.eventDate).getMinutes() / 60)) * 100 / 24}%` : `` 
                            }"
                            class="absolute left-0 w-full text-xs font-semibold rounded overflow-hidden"
                            :class="eventColor(event.type)">
                            <div class="px-2 py-0.5">{{ event.name }}</div>
                            <div class="px-2 py-0.5 hidden sm:block">
                                <span>{{ new Date(event.eventDate).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' }) }}</span>
                                <span v-if="event.eventEndDate"> - {{ new Date(event.eventEndDate).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' }) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="grid grid-cols-8 gap-px bg-slate-200 dark:bg-slate-700">
            <!-- Hour Labels Column -->
            <div class="col-span-1 grid grid-rows-24 gap-px bg-slate-200 dark:bg-slate-700">
            <div v-for="hour in hours" :key="hour" class="bg-slate-50 dark:bg-slate-800 h-20 sm:h-28 lg:h-36 flex items-center justify-center">
                <span class="text-xs sm:text-sm dark:text-slate-300 font-medium">{{ hour }}</span>
            </div>
            </div>

            <!-- Events Column -->
            <div class="col-span-7 relative bg-white dark:bg-slate-800 grid grid-rows-24 gap-px">
            <div class="relative h-20 sm:h-28 lg:h-36 z-10" v-for="(hour, index) in hours" :key="index">
                <div class="border-t border-gray-200 dark:border-gray-700 h-full" @click.prevent.stop="this.checkEvent(daysInMonth[0], hour, index) 
                                                 ? (modalOpen = true)
                                                 : null"></div>
            </div>
            <div class="absolute inset-0" >
                <div v-for="(event, index) in getEvents(daysInMonth[0])" :key="index" 
                    :style="{ top: `${(new Date(event.eventDate).getHours() + new Date(event.eventDate).getMinutes() / 60) * 100 / 24}%`, 
                                height: event.eventEndDate ? `${((new Date(event.eventEndDate).getHours() + new Date(event.eventEndDate).getMinutes() / 60) - (new Date(event.eventDate).getHours() + new Date(event.eventDate).getMinutes() / 60)) * 100 / 24}%` : `` }"
                    class="absolute left-0 w-full  text-xs font-semibold rounded overflow-hidden"
                    :class="eventColor(event.type)"
                    >
                    <div class="px-2 py-0.5">{{ event.name }} 
                    </div>
                    <div class="px-2 py-0.5 hidden sm:block">
                        <span>{{ new Date(event.eventDate).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' }) }}</span>
                        -
                        <span v-if="event.eventEndDate">{{ new Date(event.eventEndDate).toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' }) }}</span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
  import { ref } from 'vue'
  import moment from 'moment';
  moment.locale('es');
  import Multiselect from './Multiselect.vue'

  
  import ModalBasic from '@/components/ModalBasic.vue';
  
  
  export default {
    name: 'CalendarComponent',
    components: {
        ModalBasic,
        Multiselect
    },
    props:{
        usersOptions: Array,
        allowEdit: Boolean,
        blockInputs: {
            type: Boolean,
            default: function(){
                return false
            }
        },
        nameNewEvent: {
            type: String,
            default: function(){
                return null
            }
        },
        typeNewEvent: {
            type: String,
            default: function(){
                return "CALL"
            }
        },
        clientIdNewEvent: {
            type: String,
            default: function(){
                return null
            }
        },
        small: {
            type: Boolean,
            default: function(){
                return false;
            }
        },
        isLoading: Boolean,
        viewMode: String,
        currentDayStartDate: Date,
        startingBlankDays: Array,
        endingBlankDays: Array,
        daysInMonth: Array,
        filteredEvents: Array,
        month: Number,
        year: Number,
        today: Date,
    },
    emits: ['save:event', 'edit:event'],
    data(){
        const hours = [];
        for (let i = 0; i < 24; i++) {
            hours.push(`${String(i).padStart(2, '0')}:00`);
        }
        return {
            modalOpen: false,
            hours,
            eventId: null,
            dayNames: moment.weekdays(),
            isEditing: false,
            validationError: '',
            formData: {
                name: this.nameNewEvent,
                description: '',
                productId: null,
                clientId: this.clientIdNewEvent,
                notes: null,
                status: "TODO",
                type: this.typeNewEvent,
                eventDate: null,
                eventEndDate: null,
                eventHour: null,
                eventEndHour: null,
                recurrencePattern: null,
                recurrenceInterval: null,
                recurrenceEndDate: null,
                selectedUsers: [],
            }
        }
    },
    methods: {
        openModal() {
            this.modalOpen = true;
            this.isEditing = false;
            this.eventId = null;
            this.formData.name = ''
            this.formData.description = ''
            this.formData.eventDate = null
            this.formData.eventEndDate = null
            this.formData.eventHour = null
            this.formData.eventEndHour = null
            this.formData.selectedUsers = []
        },
        closeModal() {
            this.modalOpen = false;
        },        
        customLabel(option) {
            return option.username
        },
        checkEvent(day, hour, hourIndex){
            const events = this.getEvents(day);
            for(const event in events){
                if(`${String(new Date(events[event].eventDate).getHours()).padStart(2, '0')}:00` == hour && this.allowEdit) {
                    this.handleClick(null, day, events[event])
                    return true;
                } else if(`${String(new Date(events[event].eventDate).getHours()).padStart(2, '0')}:00` == hour){
                    return false;
                }
            }
            this.handleClick(hourIndex, day);
            return true;
        },
        handleClick(hourIndex, day, event=null){
            if(this.allowEdit && event != null){
                this.isEditing = true;
                this.eventId = event.id;
                this.formData.name = event.name != null ? event.name : null
                this.formData.description = event.description != null ? event.description : null
                this.formData.selectedUsers = event.selectedUsers;
                if(event.eventDate != null){
                    const date = new Date(event.eventDate);
                    this.formData.eventDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
                    this.formData.eventHour = `${String(date.getHours()).padStart(2, '0')}:00`
                }
                if(event.eventEndDate != null){
                    const date = new Date(event.eventEndDate);
                    this.formData.eventEndDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
                    this.formData.eventEndHour = `${String(date.getHours()).padStart(2, '0')}:00`
                }
            } else {
                this.isEditing = false;
                this.eventId = null;
                this.formData.name = this.nameNewEvent
                this.formData.description = ''
                this.formData.eventDate = `${this.year}-${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`
                this.formData.eventEndDate = `${this.year}-${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`
                this.formData.selectedUsers = []
                if (hourIndex == null){
                    this.formData.eventHour = null
                    this.formData.eventEndHour = null
                }else {
                    this.formData.eventHour = this.hours[hourIndex]
                    this.formData.eventEndHour = this.hours[hourIndex + 1]
                }
            }
        },
        dayFullName (date) {
            const getFullName = moment(date).format('dddd');
            return getFullName // Monday, Tuesday etcetera
        },
        eventColor(type) {
            switch (type) {
                case 'CALL':
                    return 'text-white bg-sky-500';
                case 'APPOINTMENT':
                    return 'text-white bg-indigo-500';
                case 'EVENT':
                    return 'text-white bg-amber-500';
                case 'emerald':
                    return 'text-white bg-emerald-500';
                case 'red':
                    return 'text-white bg-rose-400';
                default:
                    return 'text-white bg-red-400';
            }
        },
        getEvents (date){
            return this.filteredEvents.filter(e => new Date(e.eventDate).toDateString() === new Date(this.year, date.getMonth(), date.getDate()).toDateString())
        },
        isToday (date){
            const day = new Date(this.year, this.month, date)
            return this.today.toDateString() === day.toDateString() ? true : false
        },
        validateDates() {
            const startDateTime = new Date(`${this.formData.eventDate}T${this.formData.eventHour}`);
            const endDateTime = new Date(`${this.formData.eventEndDate}T${this.formData.eventEndHour}`);
            if (endDateTime < startDateTime) {
                this.validationError = 'Fecha de Finalizacion no puede ser anterior a la Fecha de Comienzo.';
                return false;
            }
            this.validationError = '';
            return true;
        },
        addEvent(){
            if (this.validateDates()) {
                if(this.isEditing){
                    this.$emit('edit:event', {formData: this.formData, eventId: this.eventId})
                } else{
                    this.$emit('save:event', this.formData)
                }
            }
        },  
    },    
  }
  </script>