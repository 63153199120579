<template>
  <tr>
    <td class="px-2 first:pl-5 last:pr-5 py-3">
      <div class="font-medium text-sky-500">{{document.name}}</div>
    </td>        
    <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
      <div class="space-x-1">
        <button :disabled="document.documentExtension === null" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click="downloadD(document)">
          <span class="sr-only">Download</span>
          <svg v-if="!isLoading" class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 712">
            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
          </svg>
          <font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
        </button>
        <button :disabled="document.documentExtension === null" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click.stop="previewD(document), modalOpen = true">
          <span class="sr-only">Preview</span>
          <svg v-if="!isLoading" class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 712">
            <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
          </svg>
          <font-awesome-icon v-else :icon="['fas', 'spinner']" spin />
        </button>
        <button v-if="canEdit" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full" @click.stop="modalUploadOpen = true">
          <span class="sr-only">Edit</span>
          <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
              <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
          </svg>
        </button>
        <DeleteButton v-if="canDelete" :clickHandler="() => deleteDocument(document.id)" />
      </div>
    </td>
  </tr>  
  <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, this.showData = false, this.documentLoadingSuccess = false" :title="'Preview Documento'" :size="'w-1/3'">
    <div class="flex justify-center py-3">
      <iframe v-if="this.showData && this.documentLoadingSuccess" :src="this.documentUrl" width="100%" height="500px"  frameborder="0"></iframe> 
      <button v-else :disabled="true" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </button>
    </div>
  </ModalBasic>
  <ModalBasic id="upload-modal" :modalOpen="modalUploadOpen" @close-modal="modalUploadOpen = false, this.file = null;
      this.selectedFileName = null; this.$refs.fileInput.value = null;" :title="'Subir Documento'" :size="'w-1/3'">
    <form @submit.prevent="saveD(document)" class="flex justify-center py-3">
      <label class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 mr-2" >
        <p>{{ selectedFileName || 'Elegir archivo' }}</p>
        <input class="hidden" type="file" @change="handleFC" ref="fileInput">
      </label>
      <button :disabled="isLoading" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
          <span v-if="!isLoading">{{ $t('buttons.save') }}</span>
          <span v-else>
          <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
          </span>
      </button>
    </form>
  </ModalBasic>
</template>

<script>
import ModalBasic from '@/components/ModalBasic.vue';
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import { save, downloadDocument, handleFileChange, previewDocument, getDocument } from '@/utils/handleDocuments.js';


export default {
  name: 'DocumentsTableItem',
  props: {
    document: Object,
    title: String,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    }
  },
  components:{
    ModalBasic,
    DeleteButton,
  },
  data(){
    return {
      modalOpen: false,
      modalUploadOpen: false,
      isLoading: false,
      file: null,
      selectedFileName: null,
      documentLoadingSuccess: false,
      showData: false,
      documentUrl: null,
    }
  },
  emits: ["delete-document", "save-document"], 
  methods: {
    handleFC(event) {
      handleFileChange(event, this, this.document.formats)
    },
    async downloadD(d) {    
      await downloadDocument(d, this);
    },
    previewD(document) {    
      previewDocument(document, this)
    },
    async getD(document){
      await getDocument(document, this)
    },
    deleteDocument(id) {
      // Implement delete user logic
      this.$emit('delete-document', id);
    },
    async saveD(document) {
      save(document, this)
    }
  },
}
</script>