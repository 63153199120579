import Swal from 'sweetalert2';
import api from '@/interceptors/axiosInterceptos'; 

export async function fetchData(endpoint, targetProperty, storageKey, context, loading=true, forceLoading=false) {
  try {
    const initialLoadCompleted = localStorage.getItem(`initialLoadCompleted${targetProperty}`) === 'true';
    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = true;
    }
    const token = localStorage.getItem('token');
    const headers = { 
      "authorization": `Bearer ${token}`,
    };
    const response = await api.get(`${process.env.API_URL}/${endpoint}`, { headers });
    context[targetProperty] = response.data;

    // Update localStorage with the fresh data
    localStorage.setItem(storageKey, JSON.stringify(response.data));
    localStorage.setItem(`initialLoadCompleted${targetProperty}`, 'true');
    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = false;
    }
    return response.data;
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response?.data?.detail ?? error.message
    });
    context.isLoading = false;
  }
}
