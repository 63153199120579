<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <span v-else>
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <PageTitle :title="'Categorias ✨'" />
          <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
            <AddMainButton :title="'Agregar categoria'" :clickHandler="() => {modalOpen = true}" />
          </div>
        </div>
        <GenericTable
            title="Categorias"
            :items="categories"
            :columns="columns"
            :itemsPerPage="categories.length"
          >
          <template v-slot:table-rows="{ items }">
            <tr v-for="element in items" :key="element.id">
              <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.name }}</td>
              <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                <DeleteButton :clickHandler="() => deleteElement(element.id)" />
              </td>
            </tr>
          </template>
        </GenericTable>
        <ModalBasic id="modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Agregar Categoria">
          <CategoriesForm :categories_="this.categories" @add-categories:value="add($event)" />
        </ModalBasic>
      </span>
    </BasePage>
</template>
  
<script>
  import { fetchData } from '@/utils/fetchData.js';
  import { deleteData } from '@/utils/deleteData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import ModalBasic from '@/components/ModalBasic.vue'
  import GenericTable from '@/components/GenericTable.vue'
  import DeleteButton from '@/components/buttons/DeleteButton.vue'
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  import CategoriesForm from '@/partials/categories/CategoriesForm.vue'
  
  export default {
    name: 'CategoriesPage',
    components: {
        BasePage,
        PageTitle,
        ModalBasic,
        GenericTable,
        DeleteButton,
        AddMainButton,
        CategoriesForm,
    },
    data(){
      return {
        isLoading: false,
        isDeleting: false,
        deletingId: '',
        modalOpen: false,
        categories: [],
        columns: [
          { label: 'Nombre', key: 'name' },
          { label: 'Actions', key: 'actions' }
        ],
      }
    },
    methods: {
      async deleteElement(id){
        const response = await deleteData('categories', id, this, 'La categoria se a eliminado exitosamente.', true, true)
        if(!response){
          return
        }
        const updated = this.categories.filter((c) => c.id !== id);
        localStorage.setItem('categories', JSON.stringify(updated));
        this.categories = this.categories.filter((c) => c.id !== id)
      },
      add(element){
        this.categories = element.categories
        this.modalOpen = false
      }
    },
    created(){
      loadFromLocalStorage('categories', 'categories', this);
      fetchData(`categories`, 'categories', 'categories', this);
    }
  }
</script>