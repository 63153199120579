<template>
  <div class="relative">
    <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{{ label }}</div>
    <div class="flex">

      <flat-pickr class="form-input w-full pl-9 dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium w-full" :config="config" v-model="date"></flat-pickr>
      <div class="ml-2 w-1/2">
        <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white" @click.stop="clear">
          <span class="hidden xs:block" aria-controls="feedback-modal" >Borrar</span>
        </button>
        <!-- <svg class="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 ml-3" viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg> -->
      </div>    
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'DatepickerFilter',
    components: {
      
    },
    props: {
      label: String,
      defaultDate_: Date
    },
    data () {
      return {
        date: null,
        config: {
          static: true,
          monthSelectorType: 'static',
          dateFormat: 'd/m/y',
          defaultDate: this.defaultDate_,
          prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
          nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
          onReady: (selectedDates, dateStr, instance) => {
            instance.element.value = dateStr.replace('to', '-');
          },
          onChange: (selectedDates, dateStr, instance) => {
            instance.element.value = dateStr.replace('to', '-');
            this.$emit('update:filter', selectedDates);
          },
        },                
      }
  },
  methods: {
    clear(){
      this.date = null;
      this.$emit('update:filter', null);
    }
  }

  }
</script>