export function transformPermissionsToSections(permissions, section, context) {
    const sections = [];
    permissions.forEach((permission, index) => {
      if(permission.params.includes == section){
        const route = {
          id: permission.params.id, // Assuming unique id generation here, adjust as needed
          href: permission.params.path,
          name: permission.params.name,

        };
        sections.push(route);
      }
    });
    context['selectedSection'] = sections.length > 0 ? sections[0].id : null
    return sections;
}