<template>
    <BasePage>
      <span v-if="isLoading">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <span v-else>
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <PageTitle :title="'Permisos ✨'" />
            <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
                <!-- <AddMainButton :title="'Agregar permiso'" :clickHandler="() => {modalOpen = true}" /> -->
            </div>
        </div>
        
        <GenericTable v-for="p in permissions" :key="p.category" class="my-2"
            :title="`Permisos ${p.category}`"
            :items="p.permissions"
            :columns="columns"
            :itemsPerPage="p.permissions.length"
          >
          <template v-slot:table-rows="{ items }">
            <tr v-for="element in items" :key="element.id">
                <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.friendlyName }}</td>
                <!-- <td class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                    <DeleteButton :clickHandler="() => deleteElement(element.id)" />
                </td> -->
            </tr>
          </template>
        </GenericTable>
        <ModalBasic id="modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Agregar Permiso">
          <PermissionsForm :permissions_="this.permissions" @add-permissions:value="add($event)" />
        </ModalBasic>
      </span>
    </BasePage>
</template>
  
<script>
  import { fetchData } from '@/utils/fetchData.js';
  import { deleteData } from '@/utils/deleteData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import ModalBasic from '@/components/ModalBasic.vue'
  import GenericTable from '@/components/GenericTable.vue'
  import DeleteButton from '@/components/buttons/DeleteButton.vue'
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  import PermissionsForm from '@/partials/permissions/PermissionsForm.vue'
  
  export default {
    name: 'PermissionsPage',
    components: {
        BasePage,
        PageTitle,
        ModalBasic,
        GenericTable,
        // DeleteButton,
        // AddMainButton,
        PermissionsForm,
    },
    data(){
        return {
            isLoading: false,
            isDeleting: false,
            deletingId: '',
            modalOpen: false,
            permissions: [],
            columns: [
                { label: 'Nombre', key: 'name' },
                // { label: 'Actions', key: 'actions' }
            ],
        }
    },
    methods: {
        async deleteElement(id){
            const response = await deleteData('permissions', id, this, 'El permiso se a eliminado exitosamente.', true, true)
            if(!response){
                return
            }
            const updated = this.permissions.filter((c) => c.id !== id);
            localStorage.setItem('permissions', JSON.stringify(updated));
            this.permissions = this.permissions.filter((c) => c.id !== id)
        },
        add(element){
            this.permissions = element.permissions
            this.modalOpen = false
        }
    },
    created(){
        loadFromLocalStorage('permissions', 'permissions', this);
        fetchData(`permissions`, 'permissions', 'permissions', this);
    }
  }
</script>