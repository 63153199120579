<template>
  <div class="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
    <h1 class="text-4xl font-bold text-red-500">403 - Forbidden</h1>
    <p class="text-xl text-gray-700 mt-4">
      You do not have permission to access this page.
    </p>
    <router-link
      :to="this.to"
      class="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
    >
      Go Back to Home
    </router-link>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  
  import {hasSpecificPermission} from '@/utils/hasSpecificPermission'
  import {extractUniquePermissions} from '@/utils/extractUniquePermissions'
  export default {
    name: 'ForbiddenPage',
    data(){
      return {
        to: null
      }
    },
    created(){
      hasSpecificPermission('showCalendar', 'page', ["view_calendar_page"], this)
      if(this.showCalendar){
        this.to = '/calendar'
      } else {
        const uniquePermissions = extractUniquePermissions('page');
        if (uniquePermissions.filter(e=> !e.params.hasDropdown).length > 0){
          this.to = uniquePermissions.filter(e=> !e.params.hasDropdown)[0].params.path
        }else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Rol mal configurado no cuenta con permisos para visualizar ninguna pagina',
          })
        }
      }
      
    }
  };
</script>

