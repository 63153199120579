<template>
    <BasePage>
      <!-- Page header -->
      <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <!-- Left: Title -->
          <PageTitle :title="`Servicios ✨`" />
          
          <!-- Right: Actions  -->
          <div v-if="showAdd" class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <!-- Search form -->
              
              <!-- Add member button -->
              <AddMainButton :title="'Agregar Servicio'" :clickHandler="() => create()" />
          </div>            
      </div>

      <!-- <div v-if="showFilters" class="sm:flex sm:justify-between sm:items-center mb-5">

          <div class="mb-4 sm:mb-0">
              <ul class="flex flex-wrap -m-1">
                  <li class="m-1">
                      <button class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">All <span class="ml-1 text-indigo-200">67</span></button>
                  </li>
                  <li class="m-1">
                      <button class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">Paid <span class="ml-1 text-slate-400 dark:text-slate-500">14</span></button>
                  </li>
                  <li class="m-1">
                      <button class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">Due <span class="ml-1 text-slate-400 dark:text-slate-500">34</span></button>
                  </li>
                  <li class="m-1">
                      <button class="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">Overdue <span class="ml-1 text-slate-400 dark:text-slate-500">19</span></button>
                  </li>
              </ul>
          </div>
      </div> -->
      <ProductsTable v-if="!isLoading" 
        :can-edit="showEdit" :can-delete="showDelete"
        :products="products" :items-per-page="20" @delete-p="deleteP"/>
      <span v-else>
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
    </BasePage>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';

  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import ProductsTable from '@/partials/products/ProductsTable.vue';
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  
  export default {
    name: 'ProductsListPage',
    components: {
      BasePage,
      PageTitle,
      ProductsTable,
      AddMainButton,
    },
    data() {
      return {
        products: [],
        showAdd: false,
        showEdit: false,
        showDelete: false,
        showFilters: false,
        isLoading: false,
      };
    },
    setup() {
      const sidebarOpen = ref(false)
      return {
        sidebarOpen,
      }  
    },
    methods: {
      async getProducts() {
        this.products = await fetchData(`products`, 'products', 'products', this);
      },
      create(){
        this.$router.push('/products/create-product');
      },
      deleteP(id){
        const updatedProducts = this.products.filter((product) => product.id !== id);
        localStorage.setItem('products', JSON.stringify(updatedProducts));
        this.products = this.products.filter((product) => product.id !== id)
      }
    },
    async created() {
      loadFromLocalStorage('products', 'products', this);
      this.getProducts()

      hasSpecificPermission('showFilters', 'page-filter', ["view_services_filter"], this)
      hasSpecificPermission('showAdd', 'add', ["add_service"], this)
      hasSpecificPermission('showEdit', 'edit', ["edit_service"], this)
      hasSpecificPermission('showDelete', 'delete', ["delete_service"], this)
    },
  }
  </script>