<!-- UserTable.vue -->
<template>
    <!-- Cards -->
    <div class="grid grid-cols-12 gap-6">
        <RolesTilesCard
            v-for="item in roles"
            :key="item.id"
            :item="item" :can-edit="canEdit" :can-delete="canDelete"
            @edit-role="editRole"
            @delete-role="deleteRole"
            :is-deleting="isDeleting" :deleting-id="deletingId" :isLoading="isLoading"
        />
        
    </div>
</template>
    
<script>
    import { deleteData } from '@/utils/deleteData.js';

    import RolesTilesCard from './RolesTilesCard.vue';
    export default {
      name: "RolesTilesList",
      emits: ["delete-r", "edit-r"], 
      props: {
        roles: Array,
        canEdit: {
          type: Boolean,
          default: function(){
            return false
          }
        },
        canDelete: {
          type: Boolean,
          default: function(){
            return false
          }
        }
      },
      data() {
        return {
          isLoading: false,
          isDeleting: false,
          deletingId: ''
        };
      },
      components: {
        RolesTilesCard,
      },
      methods: {
        editRole(role){
          this.$emit('edit-r', role);
        },
        async deleteRole(id) {
          const response = await deleteData('roles', id, this, 'El rol se a eliminado exitosamente.', true, true)
          if(!response){
              return
          }
          this.$emit('delete-r', id);
        },
      },
    };
</script>
    