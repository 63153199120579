<template>
    <button class="mr-2" @click="copyToClipboard(contentToCopy)"><font-awesome-icon class="mr-2" :icon="['fas', 'copy']" />{{ buttonText }}</button>
    <transition
        enter-active-class="transition ease-out duration-200 transform"
        enter-from-class="opacity-0 -translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-out duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        name="fade">
        <span v-if="isCopied" class="origin-top-right z-10 absolute bg-indigo-500 text-white text-sm px-2 py-1 rounded-md">{{ notificationText }}</span>
    </transition>
</template>

<script>
    export default {
        name: 'CopytoClipboardButton',
        props: {
            buttonText: String,
            contentToCopy: String,
            notificationText: String,
        },
        methods: {
            copyToClipboard(contentToCopy) {
                navigator.clipboard.writeText(contentToCopy)
                    .then(() => {
                    this.isCopied = true;
                    setTimeout(() => {
                        this.isCopied = false;
                    }, 2000); // Show the message for 2 seconds
                    console.log('URL copied');
                    })
                    .catch(err => {
                    console.error('Failed to copy content: ', err);
                    });
            },
        },
        data(){
            return {
                isCopied: false
            }
        }
    }
</script>