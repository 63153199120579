// src/utils/apiUtils.js
import api from '../interceptors/axiosInterceptos'; 
import Swal from 'sweetalert2';

export async function postData(endpoint, body, context, loading=true) {
  try {
    if (loading) {
      context.isLoading = true;
    }
    const token = localStorage.getItem('token');
    const headers = { 
      "authorization": `Bearer ${token}`,
    };
    const response = await api.post(`${process.env.API_URL}/${endpoint}`, body, { headers });

    if (loading) {
      context.isLoading = false;
    }
    return response.data;
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response?.data?.message ?? error.message
    });
    context.isLoading = false;
    return false
  }
}
