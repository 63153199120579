<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="btn bg-white dark:bg-slate-800 border-slate-200 hover:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <span class="sr-only">{{this.label}}</span><wbr />
      {{this.label}}
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="dropdownOpen" class="origin-top-right z-10 absolute top-full left-0 right-auto min-w-56 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 pt-1.5 rounded shadow-lg overflow-hidden mt-1" >
        <div ref="dropdown">
          <ul class="mb-4">
            <li class="py-1 px-3">
              <label class="flex items-center">
                <input type="checkbox" :checked="selectAll" class="form-checkbox" @change="updateAll" />
                <span class="text-sm font-medium ml-2">Seleccionar todos</span>
              </label>
              <label v-for="role in options" :key="role" class="flex items-center">
                <input type="checkbox" :checked="model[role]" class="form-checkbox" @change="updateModel(role)" />
                <span class="text-sm font-medium ml-2">{{role}}</span>
              </label>
            </li>
          </ul>
          <!-- <div class="py-2 px-3 border-t border-slate-200 dark:border-slate-700 bg-slate-50 dark:bg-slate-700/20">
            <ul class="flex items-center justify-between">
              <li>
                <button class="btn-xs bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 dark:text-slate-300 hover:text-slate-600 dark:hover:text-slate-200">Limpiar</button>
              </li>
            </ul>
          </div>           -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'DropdownRolesFilter',
  props: {
    label: String,
    options: Array,
    model: Object,
    selectAll_: {
      type: Boolean,
      default: function(){
        return true
      }
    }
  },
  setup() {

    const dropdownOpen = ref(false)
    const trigger = ref(null)
    const dropdown = ref(null)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return
      dropdownOpen.value = false
    }
    
    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return
      dropdownOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      dropdownOpen,
      trigger,
      dropdown,
    }
  },
  methods: {
    updateModel(option) {
      this.$emit('update:model', { ...this.model, [option]: !this.model[option] });
      this.selectAll = false
    },
    updateAll(){
      this.selectAll = !this.selectAll;
      let updated = this.model;
      for (let key in updated) {
      if (updated.hasOwnProperty(key)) {
        updated[key] = this.selectAll;
      }
      this.$emit('update:model', updated)
    }
    }
  },
  data(){
    return {
      selectAll: this.selectAll_
    }
  }
}
</script>