<template>
  <main class="bg-white dark:bg-slate-900">

    <div class="relative flex">

      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-[100dvh] h-full flex flex-col after:flex-1">

          <div class="flex-1">
            <div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
              <!-- Logo -->
              <router-link class="block" to="/">
                <LogoSvg></LogoSvg>
              </router-link>
            </div>
          </div>          

          <div class="max-w-sm mx-auto w-full px-4 py-8">
            <h1 class="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Recuperar Contraseña</h1>
            <!-- Form -->
            <form @submit.prevent="reset">
              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium mb-1" for="email">Correo Electronico<span class="text-rose-500">*</span></label>
                  <input id="email" class="form-input w-full" type="email" v-model="email" required />
                </div>
              </div>
              <div class="flex justify-end mt-6">
                <button :disabled="isLoading" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap">
                  <span v-if="!isLoading">Enviar</span>
                  <span v-else>
                      <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                  </span>
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>

      <!-- Image -->
      <div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
        <img class="object-cover object-center w-full h-full" src="../../images/auth-image.jpg" width="760" height="1024" alt="Authentication" />
        <img class="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block" src="../../images/auth-decoration.png" width="218" height="224" alt="Authentication decoration" />
      </div>

    </div>

  </main>
</template>

<script>
import Swal from 'sweetalert2';

import LogoSvg from '../../components/LogoSvg.vue';
import api from '../../interceptors/axiosInterceptos'; 

export default {
  name: 'ForgotPasswordPage',
  components: {
    LogoSvg
  },
  data() {
    return {
      email: '',
      isLoading: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    async reset() {
      if (this.isLoading) {
        return; // Prevent multiple submissions while loading
      }
      try {
        this.isLoading = true;
        const response = await api.post(`${process.env.API_URL}/password/forgot-password`, {
          email: this.email,
        });
        if(response.status == 200){
          this.successMessage = 'Email send successful!';
          this.errorMessage = ''; // Clear any previous error message
          this.isLoading = false;
          Swal.fire({
              icon: 'success',
              title: 'Exito!',
              text: 'El link se ha enviado con exito.',
          });

        } else {
            // Handle authentication error (e.g., display error message)
            
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: response.statusText,
            })
            this.isLoading = false;
          }
        // Handle successful authentication (e.g., store user token, redirect, etc.)
        this.isLoading = false;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response.statusText,
        })
            
        this.successMessage = ''; // Clear any previous success message
        // Handle authentication error (e.g., display error message)
        this.isLoading = false;
      }
    },
  },   
}
</script>