export function getProperty(item, propertyKey) {
    const keys = propertyKey.split('.');
    let result = item;
    for (const key of keys) {
      if (result === null || result === undefined){
        return null
      }
      result = result[key];
    }
    return result;
};