<template>
  <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800 dark:text-slate-100">Total de contratos <span class="text-slate-400 dark:text-slate-500 font-medium">{{this.documents?.length}}</span></h2>
    </header>
    <div>

      <!-- Table -->
      <ExtrasAccordion v-for="(element, key) in this.documents" :key="element.id" :title="key != 'null' ? element.name : 'General'">
        <div class="overflow-x-auto">
          <GenericTable
            title="Contratos"
            :items="element.contractExtras"
            :columns="columns"
            :itemsPerPage="element.contractExtras.length"
            :show-pagination="false"
          >
            <template v-slot:table-rows="{ items }">
              <ContractsTableItem
                v-for="e in items"
                :title="'title'"
                :key="e.clientContract.id"
                :document="e.clientContract"
                :todo="e.todo" :to-sign-document="e.toSignDocument" />
            </template>
          </GenericTable>
      </div>
    </ExtrasAccordion>
    </div>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue'
import ContractsTableItem from './ContractsTableItem.vue'
import ExtrasAccordion from '@/components/ExtrasAccordion.vue';

export default {
  name: 'ContractsTable',
  components: {
    GenericTable,
    ExtrasAccordion,
    ContractsTableItem,
  }, 
  emits: ["delete-p", "save-p"],
  props: {
    documents: Array, // Pass the item data as a prop
  },
  data(){
    return {
      isLoading: false,
      isDeleting: false,
      deletingId: '',
      columns: [
        { label: 'Nombre', key: 'name' },
        { label: 'Actions', key: 'actions' }
      ] 
    }
  },
  methods: {
      saveDocument(object){
        this.$emit('save-p', object);
      },
      deleteDocument(id) {
        
        this.$emit('delete-p', id);
        
      },
  }
}
</script>