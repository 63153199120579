<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else>

        <!-- Page header -->
        <div class="sm:flex sm:justify-between sm:items-center mb-4">

          <!-- Left: Title -->
          <div class="mb-4 sm:mb-0">
            <h1 class="capitalize text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold"><span>{{`${monthNames[month]} ${year}`}}</span> ✨</h1>
          </div>

          <!-- Right: Actions -->
          <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

            <!-- Previous month button -->
            <button
              class="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
              :disabled="month === 0 ? true : false"
              @click="previous"
            >
              <span class="sr-only">Previous month</span><wbr />
              <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
              </svg>
            </button>

            <!-- Next month button -->
            <button
              class="btn px-2.5 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300 disabled:border-slate-200 dark:disabled:border-slate-700 disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed"
              :disabled="month === 11 ? true : false"
              @click="next"
            >
              <span class="sr-only">Next month</span><wbr />
              <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
              </svg>
            </button>

            <hr class="w-px h-full bg-slate-200 dark:bg-slate-700 border-none mx-1" />

            <!-- Create event button -->
            <AddMainButton v-if="showAdd" :title="'Agregar Evento'" :clickHandler="() => openChildModal()" />

          </div>

        </div>

        <!-- Filters and view buttons -->
        <div class="sm:flex sm:justify-between sm:items-center mb-4">

          <!-- Filters  -->
          <div class="mb-4 sm:mb-0 mr-2">
            <ul class="flex flex-wrap items-center -m-1">
              <li class="m-1" v-for="element in eventTypesButtons" :key="element.name">
                <button class="btn-sm bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 dark:text-slate-400">
                  <div class="w-1 h-3.5 shrink-0" :class="element.color" ></div>
                  <span class="ml-1.5">{{ element.name }}</span>
                </button>
              </li>
              <!-- <li class="m-1">
                <button class="btn-sm bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500">+Agregar Tipo</button>
              </li> -->
            </ul>
          </div>
          

          <!-- View buttons (requires custom integration) -->
          <div class="flex flex-nowrap -space-x-px">
            <button
                @click="viewMode = 'month', gD(viewMode)" 
                :class="viewMode === 'month' ? 'text-indigo-500' : 'text-slate-600 dark:text-slate-300'"
                class="btn bg-slate-50 dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:bg-slate-50  dark:hover:bg-slate-900
                  rounded-none first:rounded-l last:rounded-r">{{ $t('labels.month') }}</button>
            <button 
                @click="viewMode = 'week', gD(viewMode)" 
                :class="viewMode === 'week' ? 'text-indigo-500' : 'text-slate-600 dark:text-slate-300'"
                class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-900 rounded-none first:rounded-l last:rounded-r">
                {{ $t('labels.week') }}
            </button>
            <button 
              @click="viewMode = 'day', gD(viewMode)" 
              :class="viewMode === 'day' ? 'text-indigo-500' : 'text-slate-600 dark:text-slate-300'"
              class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-900 rounded-none first:rounded-l last:rounded-r">
              {{ $t('labels.day') }}
            </button>
          </div>
        </div>
        <div v-if="showFilters">
          <ExtrasAccordion class="sm:col-span-6 mb-2" :title="'Filtros'">
            <div class="flex">

              <CheckboxFilter class="mr-4" 
              @update:filter="updateCheckBoxFilter($event, 'selectedEventType')"
              :label="'Tipo de evento'" :options="this.eventTypes" :model="this.filters.selectedEventType"/>
              <StringFilter class="mr-4"  :filter_="this.filters.nameFilter" @update:filter="updateName($event, 'nameFilter')" :label="'Nombre del Evento'" ></StringFilter>
              <div>
                <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Agentes</div>
                <DropdownRolesFilter class="w-full" label="Nombre" :options="this.filters.usersOptions" :model="this.filters.selectedUsersAgent" @update:model="updateCheckBoxFilter($event, 'selectedUsersAgent')"/>
              </div>
            </div>
          </ExtrasAccordion>
        </div>

        <!-- Calendar table -->
        <CalendarComponent ref="child"
        :users-options="this.users"
        :is-loading="this.isLoadingEdit"
        :allow-edit="true"
        @edit:event="updateEvent"
        @save:event="saveEvent"
        :view-mode="this.viewMode"
        :current-day-start-date="this.currentDayStartDate"
        :starting-blank-days="this.startingBlankDays"
        :ending-blank-days="this.endingBlankDays"
        :days-in-month="this.daysInMonth"
        :filtered-events="this.filteredEvents"
        :month="this.month"
        :year="this.year"
        :today="this.today"
        />

      </div>
  </BasePage>
</template>
  
  <script>
  import { ref } from 'vue'
  import moment from 'moment';
  import Swal from 'sweetalert2';
  moment.locale('es');

  import { getDays } from '@/utils/getDays.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { getUniqueValuesFilter } from '@/utils/getUniqueValuesFilter.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';

  
  import BasePage from '@/components/BasePage.vue';
  import api from '@/interceptors/axiosInterceptos'; 
  import ExtrasAccordion from '@/components/ExtrasAccordion.vue';
  import StringFilter from '@/components/filters/StringFilter.vue';
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  import CheckboxFilter from '@/components/filters/CheckboxFilter.vue';
  import CalendarComponent from '@/partials/calendar/CalendarComponent.vue';
  import DropdownRolesFilter from '@/partials/users/DropdownRolesFilter.vue';
  
  export default {
    name: 'CalendarPage',
    components: {
      BasePage,
      StringFilter,
      AddMainButton,
      CheckboxFilter,
      ExtrasAccordion,
      CalendarComponent,
      DropdownRolesFilter
    },
    setup() {
      const sidebarOpen = ref(false)  
  
      return {
        sidebarOpen,
      }  
    },
    data(){
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const temp = localStorage.getItem('users');
      const users = temp ? JSON.parse(temp) :[];
      // TODO: check get of sources
      const usersOptions = users.map(user => user.username);
      return {
        searchUsers: false,
        showFilters: false,
        showAdd: false,
        filters: {
          usersOptions,
          nameFilter: '',
          eventEnd: null,
          eventStart: null,
          selectedEventType: {},
          selectedUsersAgent: {}
        },
        users,
        eventTypes: [],
        isLoading: false,
        isLoadingEdit: false,
        viewMode: 'month',
        today: today,
        currentWeekStartDate: today,
        currentDayStartDate: today,
        monthNames: moment.months(),
        month: today.getMonth(),
        year: today.getFullYear(),
        daysInMonth: [],
        startingBlankDays: [],
        endingBlankDays: [],
        events: [],
        eventTypesButtons: [
          {name: 'Llamada', color: 'bg-sky-500'},
          {name: 'Cita', color: 'bg-indigo-500'},
          {name: 'Evento', color: 'bg-amber-500'},
        ]
      }
    },
    computed: {

      filteredEvents() {
         if (this.events != undefined){
 
          let filtered = this.events
          .filter((event) => event.name.toLowerCase().includes(this.filters.nameFilter.toLowerCase()))
          .filter((event) => {
              const found = this.eventTypes.find(e => e === event.type);
              return this.getSelected(this.filters.selectedEventType).some(option => option == found);
          });
          
          filtered = this.events.filter((event) => {
              const found = this.users.find(user => user.id === event.userId);
              return this.getSelected(this.filters.selectedUsersAgent).some(option => option == found.username);
          });
          
          return filtered;
        }else {
          return [];
        }
      }
    },
    methods: {
      openChildModal() {
        this.$refs.child.openModal();
      },
      gD(viewMode){
        getDays(viewMode, this)
      },
      async updateEvent(data){
        try {
          this.isLoadingEdit = true;
          const body = {
            productId: null,
            name: data.formData.name,
            description: data.formData.description,
            notes: null,
            type: "EVENT",
            eventDate: `${data.formData.eventDate} ${data.formData.eventHour}:00`,
            eventEndDate: data.formData.eventEndHour != null ? `${data.formData.eventEndDate} ${data.formData.eventEndHour}:00` : null,
            recurrencePattern: null,
            recurrenceInterval: null,
            recurrenceEndDate: null,
            selectedUsers: data.formData.selectedUsers
          }
          const token = localStorage.getItem('token');
          const headers = { 
            "authorization": `Bearer ${token}`,
          };
          const response = await api.put(`${process.env.API_URL}/events/${data.eventId}`, body, { headers });
          if(response.status == 200){
            Swal.fire({
                icon: 'success',
                title: 'Exito!',
                text: 'El evento se ha guardado con exito.',
            });
            const temp = localStorage.getItem('events');
            let eventsList = temp ? JSON.parse(temp) :[];
                
            const index = eventsList.findIndex((event) => event.id === data.eventId);

            if (index !== -1) {
              // Remove the item from the array
              eventsList.splice(index, 1);
              // Insert the updated item at the original index
              eventsList.splice(index, 0, response.data);
              localStorage.setItem('events', JSON.stringify(eventsList));
              this.events = eventsList;
            }

          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.data['error'] ?? error.response.data['message'],
          })
        }
        this.isLoadingEdit = false;
      },
      async saveEvent(formData){
        try {
          this.isLoadingEdit = true;
          const body = {
            productId: null,
            clientId: null,
            name: formData.name,
            description: formData.description,
            notes: null,
            status: "TODO",
            type: "EVENT",
            eventDate: `${formData.eventDate} ${formData.eventHour}:00`,
            eventEndDate: formData.eventEndHour != null ? `${formData.eventEndDate} ${formData.eventEndHour}:00` : null,
            recurrencePattern: null,
            recurrenceInterval: null,
            recurrenceEndDate: null,
            selectedUsers: formData.selectedUsers
          }
          const token = localStorage.getItem('token');
          const headers = { 
            "authorization": `Bearer ${token}`,
          };
          const response = await api.post(`${process.env.API_URL}/events/`, body, { headers });
          if(response.status == 200){
            const temp = localStorage.getItem('events');
            var eventsList = temp ? JSON.parse(temp) :[];
            eventsList.push(response.data)
            localStorage.setItem('events', JSON.stringify(eventsList));
            this.events = eventsList;
            Swal.fire({
                icon: 'success',
                title: 'Exito!',
                text: 'El evento se ha guardado con exito.',
            });

          }
        } catch (error) {
            Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.data['error'] ?? error.response.data['message'],
            })
        }
        this.isLoadingEdit = false;
      },
  
      getSelected(array) {
        return Object.keys(array).filter(option => array[option]);
      },
      
      updateName(value, propName) {
        this.filters[propName] = value;
      },
      updateDateRange(value, propName) {
        this.filters[propName] = value !==  null ? value['0'] : null;
      },
      updateCheckBoxFilter(value, propName){
        this.filters[propName] = { ...value };
      },
      next(){
        if(this.viewMode == 'month'){
          this.month++;
        } else if(this.viewMode == 'week'){
          this.currentWeekStartDate.setDate(this.currentWeekStartDate.getDate() + 7);
          this.month = this.currentWeekStartDate.getMonth()
        }else if(this.viewMode === 'day'){
          this.currentDayStartDate.setDate(this.currentWeekStartDate.getDate() + 1);
          this.month = this.currentWeekStartDate.getMonth()
        }
        getDays(this.viewMode, this)
      },
      previous(){
        if(this.viewMode == 'month'){
          this.month--;
        }else if(this.viewMode == 'week'){
          this.currentWeekStartDate.setDate(this.currentWeekStartDate.getDate() - 7);
          this.month = this.currentWeekStartDate.getMonth()
        }else if(this.viewMode === 'day'){
          this.currentDayStartDate.setDate(this.currentWeekStartDate.getDate() - 1);
          this.month = this.currentWeekStartDate.getMonth()
        }
        getDays(this.viewMode, this)
      },
      
      async fetchEvents() {
        this.events = await fetchData(`events`, 'events', 'events', this);
        const { uniqueValues: eventTypes, filter: selectedEventFilter } = getUniqueValuesFilter(this.events, 'type');
        this.eventTypes = eventTypes;
        this.filters.selectedEventType = selectedEventFilter;
      },
      async fetchUsers() {
        this.users = await fetchData(`users`, 'users', 'users', this);
        const { uniqueValues: users, filter: selectedUsersAgent } = getUniqueValuesFilter(this.users, 'username');
        this.filters.selectedUsersAgent = selectedUsersAgent;
      },
    }, 
    mounted(){
      getDays(this.viewMode, this);
    },
    async created(){
      loadFromLocalStorage('users', 'users', this);
      const { uniqueValues: users, filter: selectedUsersAgent } = getUniqueValuesFilter(this.users, 'username');
      this.filters.selectedUsersAgent = selectedUsersAgent;
      this.fetchUsers();
      loadFromLocalStorage('events', 'events', this);
      const { uniqueValues: eventTypes, filter: selectedEventFilter } = getUniqueValuesFilter(this.events, 'type');
      this.eventTypes = eventTypes;
      this.filters.selectedEventType = selectedEventFilter;
      this.fetchEvents();

      hasSpecificPermission('showFilters', 'page-filter', ["view_calendar_filter"], this)
      hasSpecificPermission('showAdd', 'add', ["add_events"], this)
    }
  }
  </script>