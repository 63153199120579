<template>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Contratos</h2>
        <ContractsTable 
        @save-p="saveClientDocument"
        @delete-p="deleteClientDocument"
        :documents="this.clientDocuments"/>
    </div>
  </div>  
</template>

<script>
    import { ref } from 'vue'
    import Swal from 'sweetalert2';

    import { editData } from '@/utils/editData.js';
    import api from '@/interceptors/axiosInterceptos'; 
    import ContractsTable from './contracts/ContractsTable.vue';

    export default {
        name: 'ContractsPanel',
        components: {  
            ContractsTable
        },
        props: {
            prospectId: String,
            clientDocuments_: Array
        },
        setup() {

        const sync = ref('Off')
        const addSurveyModalOpen = ref(false)

            return {
                sync,
                addSurveyModalOpen
            }
        },
        data(){
            return {
                interactions: this.interactions_,
                clientDocuments: this.clientDocuments_,
                isLoading: false,
            }
        },
        methods: {
            async saveClientDocument(object){
                await editData(`client-documents/${object.documentId}`, object.body, 'El documento se a editado exitosamente.', 'clientDocuments', 'clientDocuments', this, true);
            },
            async deleteClientDocument(documentId){
                try {
                    this.isDeleting = true;
                    this.deletingId = documentId;
                    this.isLoading = true;
                    const token = localStorage.getItem('token');
                    const headers = { 
                    "authorization": `Bearer ${token}`,
                    };

                    const response = await api.delete(`${process.env.API_URL}/client-documents/${documentId}`, { headers });
                    if(response.status == 200){
                        
                        Swal.fire({
                            icon: 'success',
                            title: 'Exito!',
                            text: 'El Documento se a eliminado exitosamente.',
                        });
                        
                            
                        const index = this.clientDocuments.findIndex((document) => document.id === documentId);

                        if (index !== -1) {
                            this.clientDocuments.splice(index, 1);
                            this.clientDocuments.splice(index, 0, response.data);
                            localStorage.setItem('clientDocuments', JSON.stringify(this.clientDocuments));
                            this.isLoading = false;
                        }
                    
                    }
                    this.isDeleting = true;
                    this.isLoading = false;
                    this.deletingId = '';
                    
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data['error'],
                    })
                    this.isDeleting = true;
                    this.isLoading = false;
                    this.deletingId = '';
                }    
            },
        },        
    }
</script>