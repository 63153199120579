<template>
  <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800 dark:text-slate-100">Total de documentos <span class="text-slate-400 dark:text-slate-500 font-medium">{{this.documents?.length}}</span></h2>
    </header>
    <div>
      <ExtrasAccordion v-for="(element, key) in grouped" :key="element.id" :title="key != 'null' ? element[0].clientProduct?.name : 'General'">
        <GenericTable
          title="Documentos"
          :items="element"
          :columns="columns"
          :itemsPerPage="itemsPerPage"
          :show-pagination="true"
        >
          <template v-slot:table-rows="{ items }">
            <DocumentsTableItem
              v-for="document in items" :can-delete="canDelete" :can-edit="canEdit"
              :title="'title'"
              :key="document.id"
              :document="document"
              @save-document="saveDocument"
              @delete-document="deleteDocument"/>
          </template>
        </GenericTable>
    </ExtrasAccordion>
    </div>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue'
import DocumentsTableItem from './DocumentsTableItem.vue'
import ExtrasAccordion from '@/components/ExtrasAccordion.vue';

export default {
  name: 'DocumentsTable',
  components: {
    GenericTable,
    ExtrasAccordion,
    DocumentsTableItem,
  }, 
  emits: ["delete-p", "save-p"],
  props: {
    documents: Array,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    }
  },
  data(){
    return {
      itemsPerPage: 20,
      isLoading: false,
      isDeleting: false,
      deletingId: '',
      columns: [
        { label: 'Nombre', key: 'name' },
        { label: 'Actions', key: 'actions' }
      ]
    }
  },
  computed: {
    grouped() {
      let grouped = this.documents.reduce((acc, element) => {
        let key = element.clientProductId ? element.clientProductId : 'null';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(element);
        return acc;
      }, {});
      return grouped;
    },
  },
  methods: {
    saveDocument(object){
      this.$emit('save-p', object);
    },
    deleteDocument(id) {
      this.$emit('delete-p', id);
    },
  }
}
</script>