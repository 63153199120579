<!-- UserTable.vue -->
<template>
    <!-- Cards -->
    <div class="grid grid-cols-12 gap-6">
        <UsersTilesCard
            v-for="item in filteredUsers"
            :key="item.id"
            :item="item" :can-edit="canEdit" :can-delete="canDelete"
            @edit-user="editUser"
            @delete-user="deleteUser"
            :is-deleting="isDeleting" :deleting-id="deletingId"
        />
    </div>

    <!-- Pagination -->
    <div class="mt-8">
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <nav class="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
          <ul class="flex justify-center">
            <li class="ml-3 first:ml-0">
              <button @click="prevPage" :disabled="currentPage === 1" class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-300 dark:text-slate-600">&lt;- Previous</button>
            </li>
            <li class="ml-3 first:ml-0">
              <button @click="nextPage" :disabled="currentPage === totalPages" class="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500" href="#0">Next -&gt;</button>
            </li>
          </ul>
        </nav>
        <div class="text-sm text-slate-500 dark:text-slate-400 text-center sm:text-left">
          Mostrando <span class="font-medium text-slate-600 dark:text-slate-300">{{ startIndex }}</span> a <span class="font-medium text-slate-600 dark:text-slate-300">{{endIndex}}</span> de <span class="font-medium text-slate-600 dark:text-slate-300">{{this.users?.length}}</span> resultados
        </div>
      </div>
    </div>           
</template>
    
<script>
  import { deleteData } from '@/utils/deleteData.js';

  import UsersTilesCard from '@/partials/users/UsersTilesCard.vue';

    export default {
      name: "UsersTilesList",
      emits: ["delete-u"], 
      props: {
        users: Array, // Pass the item data as a prop
        roles: Array,
        itemsPerPage: Number,
        selectedRoles: Object,
        nameFilter: String,
        canEdit: {
          type: Boolean,
          default: function(){
            return false
          }
        },
        canDelete: {
          type: Boolean,
          default: function(){
            return false
          }
        }
      },
      data() {
        
        return {
          currentPage: 1,
          startIndex: 1,
          endIndex: this.users?.length > this.itemsPerPage ? this.itemsPerPage : this.users?.length,
          // nameFilter: '',
          emailFilter: '',
          isLoading: false,
          isDeleting: false,
          deletingId: ''
        };
      },
    components: {
        UsersTilesCard,
    },
      computed: {
        
        filteredUsers() {
          if (this.users != undefined){

          
            let filtered = this.users
              .filter((user) => {
                  const userRoles = user.roles.map(role => role.name);
                  return this.getSelectedRoles().some(selectedRole => userRoles.includes(selectedRole));
              })
              .filter((user) => user.username.toLowerCase().includes(this.nameFilter.toLowerCase()))
              .filter((user) => user.email.toLowerCase().includes(this.emailFilter.toLowerCase()));
      
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return filtered.slice(startIndex, endIndex).map(user => ({
              ...user,
              roles: user.roles.map(role => role.name).join(', '), // Concatenate roles
            }));
          }else {
            return [];
          }
        },
        totalPages() {
          if (this.users != undefined){

            return Math.ceil(
              this.users
              // .filter((user) => this.selectedRoles[user.role])
              // .filter((user) => user.name.toLowerCase().includes(this.nameFilter.toLowerCase()))
              // .filter((user) => user.email.toLowerCase().includes(this.emailFilter.toLowerCase()))
              .length / this.itemsPerPage
            );
          } else {
            return 0;
          }
        },
      },
      methods: {
        createUser(){
          this.$router.push('/users/create-user'); // Or use a custom route if defined
        },
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
            this.startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
            this.endIndex = this.startIndex + this.itemsPerPage - 1;
          }
        },
        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
            this.endIndex = this.users.length < this.startIndex + this.itemsPerPage - 1 ? this.users.length :  this.startIndex + this.itemsPerPage - 1;
          }
        },
        applyFilters() {
          this.currentPage = 1;
        },
        editUser(user) {    
            this.$router.push(`/users/${user.id}`);
        },
        async deleteUser(id) {
          const response = await deleteData('users', id, this, 'El usuario se a eliminado exitosamente.', true, true)
          if(!response){
              return
          }
          this.$emit('delete-u', id);
        },
        getSelectedRoles() {
          return Object.keys(this.selectedRoles).filter(role => this.selectedRoles[role]);
        },
      },
    };
</script>
    