<template>
  <button @click="selectProduct(product)" class="text-left bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4">
    <!-- Body -->
    <div class="mb-3">
      <!-- Title -->
      <h2 class="font-semibold text-slate-800 dark:text-slate-100 mb-1">{{product.name}}</h2>
      <!-- Content -->
      
    </div>
    <!-- Footer -->
       
    <span class="flex items-center justify-between" v-if="product.extras?.length > 0 && product.stepExtras?.length == 0 && doneDocumentsPercentage !== 100">
      <ToolTip bg="dark">
        <div class="text-xs text-slate-200 whitespace-nowrap">
          Documentos subdidos: En Progreso: {{progressDocumentsPercentage}}% Completado: {{ doneDocumentsPercentage }}%</div>
      </ToolTip>
      <ProgressBar  :progress-width="progressDocumentsPercentage" :done-progress="doneDocumentsPercentage" />
    </span>
    <span class="flex items-center justify-between" v-else-if="product.contractExtras?.length > 0 && product.stepExtras?.length == 0">
      <ToolTip bg="dark">
        <div class="text-xs text-slate-200 whitespace-nowrap">
          Contratos firmados: En Progreso: {{progressContractsPercentage}}% Completado: {{ doneContractsPercentage }}%</div>
      </ToolTip>
      <ProgressBar  :progress-width="progressContractsPercentage" :done-progress="doneContractsPercentage" />
    </span>
    <span class="flex items-center justify-between" v-else-if="product.stepExtras?.length > 0">
      <ToolTip bg="dark">
        <div class="text-xs text-slate-200 whitespace-nowrap">
          Pasos completados: En Progreso: {{progressStepsPercentage}}% Completado: {{ doneStepsPercentage }}%</div>
      </ToolTip>
      <ProgressBar  :progress-width="progressStepsPercentage" :done-progress="doneStepsPercentage" />
    </span>
    
  </button>
</template>

<script>
import ToolTip from '@/components/ToolTip.vue';
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  name: 'AplicableProductsTableItem',
  props: ['product', 'value'],
  components: {
    ToolTip,
    ProgressBar
  },
  data(){
    return {
      doneDocumentsPercentage: null,
      progressDocumentsPercentage: null,
      doneContractsPercentage: null,
      progressContractsPercentage: null,
      doneStepsPercentage: null,
      progressStepsPercentage: null,
    }
  },
  methods: {
    calculateProgressAndDone(tasks) {
      let totalTasks = tasks.length;
      let doneCount = 0;
      let progressCount = 0;

      tasks.forEach(task => {
        if (task.clientDocument.documentExtension && task.todo.status == 'DONE') {
          doneCount++;
        } else if (task.clientDocument.requestDuringSignature) {
          progressCount++;
        }
      });
      

      this.doneDocumentsPercentage = totalTasks > 0 ? (doneCount / totalTasks) * 100 : 0;
      this.progressDocumentsPercentage = totalTasks > 0 ? (progressCount / totalTasks) * 100 : 0;
      
    },
    calculateProgressAndDoneContracts(tasks) {
      let totalTasks = tasks.length;
      let doneCount = 0;
      let progressCount = 0;

      tasks.forEach(task => {
        if (task.todo.status == 'DONE') {
          doneCount++;
        } else {
          progressCount++;
        }
      });
      

      this.doneContractsPercentage = totalTasks > 0 ? (doneCount / totalTasks) * 100 : 0;
      this.progressContractsPercentage =  totalTasks > 0 ? (progressCount / totalTasks) * 100 : 0;

    },
    calculateProgressAndDoneSteps(tasks) {
      let totalTasks = tasks.length;
      let doneCount = 0;
      let progressCount = 0;

      tasks.forEach(task => {
        if (task.todo.status == 'DONE') {
          doneCount++;
        } else if (task.todo.status == 'INPROGRESS') {
          progressCount++;
        }
      });
      this.doneStepsPercentage = totalTasks > 0 ? (doneCount / totalTasks) * 100 : 0;
      this.progressStepsPercentage = totalTasks > 0 ? (progressCount / totalTasks) * 100 : 0;
    },
    selectProduct() {
      this.$emit('select-product', this.product);
    },
    editProspect(prospect) {
      if(this.$route.path == '/clients'){
        this.$router.push(`/clients/${prospect.id}`);
      } else {
        this.$router.push(`/prospects/${prospect.id}`);
      }
    },
    deleteProspect(id) {
      // Implement delete user logic
      this.$emit('delete-prospect', id);
    },
  },
  created(){
    this.calculateProgressAndDone(this.product.extras);
    this.calculateProgressAndDoneContracts(this.product.contractExtras ?? []);
    this.calculateProgressAndDoneSteps(this.product.stepExtras??[]);
  }
}
</script>