<template>
    <button v-if="allDone && this.product.status === 'INPROGRESS'" class="btn mb-2 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 mr-2" 
        @click.stop.prevent="finishProduct(product)">
        <span v-if="!isLoading">Finalizar Servicio</span>
        <span v-else>
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
    </button>
</template>

<script>
    export default {
        name: 'FinishServiceButton',
        props: {
            product: Object,
            allDone: Boolean,
            finishProduct: Function
        }
    }
</script>