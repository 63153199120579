// src/utils/apiUtils.js
import {extractUniquePermissions} from './extractUniquePermissions';

export async function hasSpecificPermission(targetProperty, filter, permissions, context) {
    const uniquePermissions = extractUniquePermissions(filter);
    
    const targetPath = targetProperty.split('.');

    setNestedProperty(context, targetPath, permissions.some(permission =>
        uniquePermissions.some(p => p.name === permission)
    ));
}


function setNestedProperty(obj, pathArray, value) {
    pathArray.reduce((acc, key, index) => {
        // If we're at the end of the path, set the value
        if (index === pathArray.length - 1) {
            acc[key] = value;
        } else {
            // If the key doesn't exist, create an empty object
            if (!acc[key]) {
                acc[key] = {};
            }
            return acc[key];
        }
    }, obj);
}