<template>
  <div class="relative">
    <div
      class="rounded-full border-2 border-white dark:border-slate-800 box-content bg-slate-500 text-white flex items-center justify-center"
      :style="{ width: size + 'px', height: size + 'px' }"
      @mouseenter="tooltipOpen = true"
      @mouseleave="tooltipOpen = false"
      @focusin="tooltipOpen = true"
      @focusout="tooltipOpen = false"
      tabindex="0"
    >
      <span>{{ extractInitials(name) }}</span>
    </div>
    <div v-show="tooltipOpen" class="z-10 absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2">
      <transition
        enter-active-class="transition ease-out duration-200 transform"
        enter-from-class="opacity-0 -translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-out duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          class="rounded border overflow-hidden shadow-lg p-2 bg-slate-700 text-white text-sm"
        >
          {{ name }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    name: {
      type: String,
      default: 'john',
    },
    size: {
      type: Number,
      default: 28, // Default size is 28x28 pixels
    },
    
  },
  setup() {
    const tooltipOpen = ref(false);

    return {
      tooltipOpen,
    };
  },
  methods: {
    extractInitials(input) {
        // Split the input string by spaces or underscores
        const words = input.split(/[_\s]/);

        // Extract the first letter of the first two words and capitalize them
        const initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');

        return initials;
    }
  }
};
</script>

<style scoped>
span {
  font-size: 1rem; /* Adjust font size based on the component size */
  line-height: 1; /* Ensure the text is centered vertically */
}
</style>
