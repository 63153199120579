<template>
    <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4" draggable="true">
        <div class="sm:flex sm:justify-between">
            <!-- Left side -->
            
            <div class="flex items-center">
                <!-- Drag button -->
                <button class="cursor-move mr-2">
                    <span class="sr-only">Drag</span>
                    <svg class="w-3 h-3 fill-slate-300 dark:fill-slate-600" viexBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1h12v2H0V1Zm0 4h12v2H0V5Zm0 4h12v2H0V9Z" fill-rule="evenodd" />
                    </svg>
                </button>
                <!-- Checkbox button -->
                <label class="flex items-center w-44">
                    <input type="checkbox" :disabled="todo.status == 'DONE' || disableCheck" :checked="todo.status == 'DONE'" class="form-checkbox w-5 h-5 rounded-full peer" @change="onChecked(todo)"/>
                    <span class="font-medium text-slate-800 dark:text-slate-100 peer-checked:line-through ml-2 line-clamp-2">{{todo.name}}</span>
                </label>
            </div>
            
            <!-- Right side -->
            <div class="md:flex items-center justify-end space-x-3">
                <!-- Avatars -->
                <div class="flex justify-center my-2 md:my-0 shrink-0 md:-space-x-3 md:-ml-px">
                    <a class="block" href="#0">
                    <img class="rounded-full border-2 border-white dark:border-slate-800 box-content" src="../images/user-32-07.jpg" width="24" height="24" alt="User 07" />
                    </a>
                </div>
                <div class="pl-4 md:pl-0 text-sm text-slate-800 dark:text-slate-100 font-medium w-44"><p>{{ document.documentExtension !== null && document.documentExtension !== undefined ? `${document.name}${document.documentExtension}`: 'Elegir archivo' }}</p></div>
                <svg v-if="todo.status == 'TODO' && document.documentExtension !== null && document.documentExtension !== undefined && document.documentExtension !== ''" class="w-4 h-4 shrink-0 fill-current mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <span class="sr-only">Check signal</span>
                    <path 
                    :class="'text-green-500'"
                    d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                </svg>
                <div class="flex justify-center gap-4 my-2">
                <button v-if="!document.requestDuringSignature" @click.stop="modalUploadOpen = true">
                    <span class="sr-only">Upload</span>
                    <font-awesome-icon class="h-4" :icon="['fas', 'upload']" />
                </button>
                <button :disabled="document.documentExtension === null || document.documentExtension === undefined || document.documentExtension === ''" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full disabled:opacity-35" @click.stop="previewD(document), modalOpen = true">
                    <span class="sr-only">Preview</span>
                    <font-awesome-icon class="h-4" v-if="!isLoading" :icon="['fas', 'eye']" />
                    <font-awesome-icon class="h-4" v-else :icon="['fas', 'spinner']" spin />
                </button>
                </div>
                <!-- Attach button -->
                <label class="flex items-center">
                <input type="checkbox" 
                :disabled="todo.status == 'DONE' || disableInputs || (todo.status == 'TODO' && document.documentExtension !== null && document.documentExtension !== undefined && document.documentExtension !== '')" :checked="document.requestDuringSignature" 
                class="form-checkbox w-5 h-5 rounded-full peer" @change="onPressed(this.todo.id)"/>
                <span class="font-medium text-slate-800 dark:text-slate-100 ml-2">Solicitar durante el proceso de Firma</span>
                </label>
            
            </div>
            <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, this.showData = false, this.documentLoadingSuccess = false" :title="'Preview Documento'" :size="'max-w-2xl'" :document-row="true">
                <!-- <PdfDocument v-if="this.showData && this.documentLoadingSuccess" :documentUrl="this.documentUrl" :isLoading="false"/>-->
                <div class="flex justify-center py-3">

                <iframe v-if="this.showData && this.documentLoadingSuccess" :src="this.documentUrl+ '#toolbar=1&navpanes=0'" width="100%" height="500px"  frameborder="0"></iframe> 
                <button v-else :disabled="true" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </button>
                </div>
                <div v-if="todo.status == 'TODO' && this.documentLoadingSuccess" class="border-t border-slate-200 dark:border-slate-700">
                    <div class="flex divide-x divide-slate-200 dark:divide-slate-700">
                    <button :disabled="!permissions.canValidateDocument" @click.prevent.stop="validate({document, todo, action: 'validateDocument'})" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
                        <div class="flex items-center justify-center">
                            <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path 
                                :class="'text-green-500'"
                                d="M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM305 273L177 401c-9.4 9.4-24.6 9.4-33.9 0L79 337c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L271 239c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                            </svg>
                            <span>Validar</span>
                        </div>
                    </button>
                    <button :disabled="!permissions.canValidateDocument" @click.prevent.stop="reject({document, todo, action: 'rejectDocument'})" class="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group">
                        <div class="flex items-center justify-center">
                        <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                            <path :class="'text-red-500'" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                            <path :class="'text-red-500'" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                        </svg>
                        <span>Rechazar</span>
                        </div>
                    </button>
                    </div>
                </div>

            </ModalBasic>
            <ModalBasic id="upload-modal" :modalOpen="modalUploadOpen" @close-modal="modalUploadOpen = false, this.file = null;
                this.selectedFileName = null; this.$refs.fileInput.value = null;" :title="'Subir Documento'" :size="'w-full md:w-1/2'">
                <form @submit.prevent.stop="saveD(document)" class="flex justify-center py-3">
                    <label class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 mr-2" >
                        <p>{{ selectedFileName || 'Elegir archivo' }}</p>
                        <input class="hidden" type="file" @change="handleFC" ref="fileInput">
                    </label>
                    <button :disabled="isLoading" type="submit" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                        <span v-if="!isLoading">{{ $t('buttons.save') }}</span>
                        <span v-else>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                        </span>
                    </button>
                </form>
            </ModalBasic>
        </div>
    </div>
</template>
<script>
    import ModalBasic from '@/components/ModalBasic.vue';
    import { save, downloadDocument, handleFileChange, previewDocument, getDocument } from '@/utils/handleDocuments.js';

    export default {
        name: 'ToDoDocumentRow',
        components: {
            ModalBasic
        },
        props:{
            permissions: Object,
            disableInputs: Boolean,
            disableCheck: Boolean,
            todo: Object,
            document: Object
        },
        data(){
            return {
                modalOpen: false,
                modalUploadOpen: false,
                isLoading: false,
                file: null,
                selectedFileName: null,
                documentLoadingSuccess: false,
                showData: false,
                documentUrl: null,
            }
        },
        methods: {
            onChecked(todo){
                this.$emit('checked:value', todo);
            },
            onPressed(value){
                this.$emit('pressed:value', value);
            },
            handleFC(event) {
                handleFileChange(event, this, this.document.formats)
            },
            async downloadD(d) {    
                await downloadDocument(d, this);
            },
            previewD(document) {    
                previewDocument(document, this)
            },
            async getD(document){
                await getDocument(document, this)
            },
            async saveD(document) {
                save(document, this)
            },
            validate(object){
                this.$emit('validate:value', object);
            },
            reject(object){
                this.$emit('reject:value', object);
            },
        },
    }
</script>