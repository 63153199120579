<template>
    <BasePage>
        <span v-if="isLoading">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
        </span>
        <span v-else>
            <div class="sm:flex sm:justify-between sm:items-center mb-8">
                <!-- Left: Title -->
                <PageTitle :title="`Roles ✨`" />
                <!-- Right: Actions  -->
                <div v-if="showAdd" class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <AddMainButton :title="'Agregar rol'" :clickHandler="() => {modalOpen = true, this.clean, this.componentKey++}" />
                    <div class="m-1.5">
                    <!-- Start -->
                    <ModalBasic id="feedback-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, clean()" :title="isEditing ? 'Editar Rol' : 'Agregar Rol'">
                        <!-- Modal content -->
                        <RolesForm 
                        :key="this.componentKey" :is-editing="isEditing" :editing-id="editingId"
                        :form-data_="this.role" :permissions="this.permissions" :roles_="this.roles" 
                        @emit-roles:value="handleEmit"
                        />
                    </ModalBasic>
                    <!-- End -->
                    </div>
                </div>            

            </div>
            <div class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
                <div class="w-full">
                    <RolesTilesList 
                        :key="this.componentKey" :can-edit="showEdit" :can-delete="showDelete"
                        @edit-r="editRole" @delete-r="deleteRole" :roles="this.roles"
                    />
                </div>
            </div>
        </span>
    </BasePage>
</template>

<script>
    import { fetchData } from '@/utils/fetchData.js';
    import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
    import {hasSpecificPermission} from '@/utils/hasSpecificPermission.js';
    
    import BasePage from '@/components/BasePage.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ModalBasic from '@/components/ModalBasic.vue';
    import RolesForm from '@/partials/roles/RolesForm.vue'
    import RolesTilesList from '@/partials/roles/RolesTilesList.vue';
    import AddMainButton from '@/components/buttons/AddMainButton.vue'
    
    export default {
        name: 'RolesListPage',
        components: {
            BasePage,
            PageTitle,
            RolesForm,
            ModalBasic,
            AddMainButton,
            RolesTilesList
        },
        data(){
            return {
                componentKey: 0,
                editingId: null,
                modalOpen: false,
                isEditing: false,
                permissions: [],
                showAdd: false,
                showEdit: false,
                showDelete: false,
                roles: [],
                isLoading: false,
                isDeleting: false,
                deletingId: '',
                role: {
                    name: '',
                    description: '',
                    permissions: []
                },
            }
        },
        methods: {
            async getData() {
                this.roles = await fetchData(`roles`, 'roles', 'userRoles', this);
                this.permissions = await fetchData(`permissions`, 'permissions', 'permissions', this);
            },
            async deleteRole(id){
                const updatedRoles = this.roles.filter((role) => role.id !== id);
                localStorage.setItem('userRoles', JSON.stringify(updatedRoles));
                this.roles = this.roles.filter((role) => role.id !== id)
            },
            async handleEmit(element) {
                this.roles = element.roles
                this.clean()
            },
            clean(){
                this.modalOpen = false;
                this.editingId = null;
                this.isEditing = false;
                this.role = {
                    name: '',
                    description: '',
                    permissions: []
                };
            },
            editRole(role){
                this.isEditing = true;
                this.editingId = role.id;
                this.role = {
                    name: role.name,
                    description: role.description,
                    permissions: role.Permissions.map(p=> p.id)
                };
                this.modalOpen = true;
                this.componentKey++;
            },
        },
        async created() {
            loadFromLocalStorage('userRoles', 'roles', this);
            loadFromLocalStorage('permissions', 'permissions', this);
            hasSpecificPermission('showAdd', 'add', ["add_role"], this)
            hasSpecificPermission('showEdit', 'edit', ["edit_role"], this)
            hasSpecificPermission('showDelete', 'delete', ["delete_role"], this)

            this.getData();
        },
    }
</script>