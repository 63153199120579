<template>
    <div ref="container-fluid h-100" class="pdf-container">
      <div class="flex mb-2">
        <span class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 ml-2 mr-2" @click="goToPreviousPage">página anterior</span>
        <span class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2" @click="goToNextPage">página siguiente</span>
      </div>
      
      <canvas ref="canvas" class="pdf-canvas"></canvas>
      <div style="height: 150px;"></div>
    </div>
    
  </template>
  
  <script>

  import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';  
  export default {
    props: {
        documentUrl: {
            type: String,
            required: true
        },
        publicKey: {
          type: String,
          required: true
        },
        isLoading: Boolean,
    },
    data() {
      return {
        isCanvasEmpty: true,
        isDocumentFullyScrolled: false,
        currentPage: 1,
        totalPages: 0,
        scale: .6,
        pdfInstance: null,
      };
    },
    mounted() {
      this.renderPDF();
    },
    methods: {
      calculateScaleFactor(page) {
        const devicePixelRatio = window.devicePixelRatio || 1;
        const screenWidth = window.innerWidth;
        const pageWidth = page.getViewport({ scale: 1 }).width;
        
        let scale;
        if (screenWidth < 600) {
            // For mobile devices
            scale = 0.6;
        } else if (pageWidth > screenWidth) {
            // If the document width is larger than the screen width, scale to fit
            scale = pageWidth/ screenWidth;
        } else {
            // For desktop devices
            scale = 1.5;
        }
        
        return scale;
      },
      async renderPDF() {
        const container = this.$refs.container;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');

        // pdfjsLib.GlobalWorkerOptions.workerSrc = 'path/to/pdf.worker.js';
        // GlobalWorkerOptions.workerSrc = URL.createObjectURL(
        //   new Blob([PDFWorker.getWorkerSrc()], { type: 'application/javascript' })
        // );
      // GlobalWorkerOptions.workerSrc = window.location.origin + '/js/pdf.worker.js';

        GlobalWorkerOptions.workerSrc ="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.5.136/pdf.worker.min.mjs";
        const loadingTask = getDocument(this.documentUrl);

        loadingTask.promise.then(pdf => {
          this.pdfInstance = pdf;
          this.totalPages = pdf.numPages;

          pdf.getPage(this.currentPage).then(page => {
            let scale = this.calculateScaleFactor(page);

            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
          });
        },function(reason) {
    console.error('Error: ' + reason);
  });
      },  
      goToPreviousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.renderPage(this.currentPage);
        }
      },
      goToNextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
          this.renderPage(this.currentPage);
        }
        if (this.currentPage == this.totalPages) {
          this.isDocumentFullyScrolled = true;
        }
      },
      renderPage(pageNumber) {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        const loadingTask = getDocument(this.documentUrl);
  
        loadingTask.promise.then(pdf => {
          this.pdfInstance = pdf;
          this.totalPages = pdf.numPages;
  
          pdf.getPage(pageNumber).then(page => {
            let scale = this.calculateScaleFactor(page);

            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
  
            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
          });
        });
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  