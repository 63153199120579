<template>
    <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-4" draggable="true">
        <div class="sm:flex sm:justify-between sm:items-start">
            <!-- Left side -->
            <div class="grow mt-0.5 mb-3 sm:mb-0 space-y-3">
            <div class="flex items-center">
                <ToolTip v-if="shouldShowToolTip" bg="dark" class="mr-2">
                    <div class="text-xs text-red-500 whitespace-nowrap">
                    Fecha Limite: {{ this.dueDate }}</div>
                </ToolTip>
                <!-- Checkbox button -->
                <label class="flex items-center">
                <input type="checkbox" :disabled="(todo.status == 'DONE' && disableWhenDone) || blocked || disableCheck" :checked="todo.status == 'DONE'" class="form-checkbox w-5 h-5 rounded-full peer" @change="onChecked(todo)"/>
                <span class="font-medium text-slate-800 dark:text-slate-100 peer-checked:line-through ml-2">{{todo.name}}</span>
                <span v-if="blocked" class="font-medium text-slate-800 dark:text-slate-100 peer-checked:line-through ml-2">- Bloqueado</span>
                </label>
            </div>
            </div>
            <!-- Right side -->
            <div class="flex items-center justify-end space-x-3">
            <!-- Avatars -->
            <div class="flex shrink-0 -space-x-3 -ml-px">
                <a class="block" href="#0">
                <img class="rounded-full border-2 border-white dark:border-slate-800 box-content" src="../images/user-32-07.jpg" width="24" height="24" alt="User 07" />
                </a>
            </div>
            <!-- Date -->
            <div v-if="showAttachButton" class="flex items-center" :class="color">
                <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                </svg>
                <div class="text-sm" :class="color">
                    {{eventDate}}</div>
            </div>
            <div v-if="authorization && todo.status == 'TODO'" class="flex items-center">
                <button v-if="canAuthorize" @click.prevent.stop="validate({todo, action: 'authorizeStep'})"><font-awesome-icon :icon="['fas', 'check']" class="text-green-500 mr-2"/></button>
                <button v-if="canAuthorize" @click.prevent.stop="reject({todo, action: 'rejectService'})"><font-awesome-icon :icon="['fas', 'xmark']" class="text-red-500 mr-2"/></button>
                <div class="text-sm text-amber-600">
                    Pendiente de autorizar</div>
            </div>
            <!-- Attach button -->
            <button v-if="showAttachButton" :disabled="todo.status == 'DONE' || blocked" @click.prevent.stop="onPressed(this.id)" class="text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                <svg class="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0zM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2z" />
                </svg>
            </button>
            </div>
        </div>
    </div>
</template>
<script>
    import ToolTip from '@/components/ToolTip.vue';
    export default {
        name: 'ToDoRow',
        props:{
            canAuthorize: {
                type: Boolean,
                default: function(){
                    return false;
                }
            },
            disableCheck: {
                type: Boolean,
                default: function(){
                    return false;
                }
            },
            authorization: {
                type: Boolean,
                default: function(){
                    return false;
                }
            },
            blocked: {
                type: Boolean,
                default: function(){
                    return false
                }
            },
            showAttachButton: {
                type: Boolean,
                default: function(){
                    return true
                }
            },
            disableWhenDone: {
                type: Boolean,
                default: function(){
                    return true
                }
            },
            id: String,
            todo: Object,
        },
        computed: {
            shouldShowToolTip() {
                const now = new Date();
                let formattedDueDate = this.dueDate.replace(' p.m.', ' PM').replace(' a.m.', ' AM');
  
                // Use Date.parse to convert the formatted string into a Date object
                const dueDate = new Date(Date.parse(formattedDueDate));

                // If the date is invalid, return false early
                if (isNaN(dueDate.getTime())) {
                    console.error('Invalid dueDate:', this.dueDate);
                    return false;
                }
                
                // Calculate the difference in milliseconds
                const timeDifference = dueDate - now;
                
                // Convert the time difference to hours
                const hoursDifference = timeDifference / (1000 * 60 * 60);

                return (hoursDifference <= 24 || hoursDifference < 0) && this.todo.status !== 'DONE';
            },
        },
        methods: {
            onChecked(todo){
                this.$emit('checked:value', todo);
            },
            onPressed(value){
                this.$emit('pressed:value', value);
            },
            validate(object){
                this.$emit('validate:value', object);
            },
            reject(object){
                this.$emit('reject:value', object);
            },
        },
        data(){
            var eventDate;
            var dueDate = '';
            var color;
            if (this.todo?.event?.eventDate !== null && this.todo?.event?.eventDate !== undefined){
                const date = new Date(this.todo.event.eventDate);
                eventDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })}`
                color = 'text-green-500'
            } else if(this.todo.realizedDate !== null){
                eventDate = ''
            } else {
                eventDate = 'Pendiete Agendar'
                color = 'text-amber-600'
            }
            if (this.todo?.dueDate !== null && this.todo?.dueDate !== undefined){
                const date = new Date(this.todo.dueDate);
                dueDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })}`
            }
            return {
                dueDate,
                eventDate,
                color
            }
        },
        components: {
            ToolTip
        }
    }
</script>