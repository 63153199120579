<template>
    <BasePage>
        <div class="sm:flex sm:justify-between sm:items-center">

            <!-- Left: Title -->
            <PageTitle :title="'Usuarios ✨'" />

            <!-- Right: Actions  -->
            <div v-if="showAdd" class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <!-- Search form -->
                <AddMainButton :title="'Agregar usuario'" :clickHandler="() => createUser()" />
                
            </div>            

        </div>
        <LoginUsers :users_="this.users" @update="updateValues"/>
        <div class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">

            <!-- Sidebar -->
            <UsersSidebar v-if="!isLoading && showFilters" :roles="rolesOptions" :model="selectedRoles" @update:model="updateUserRoles" @update:email="updateEmail" />
            <span v-else-if="!showFilters"></span>
            <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
            <div class="w-full">

                <span v-if="!isLoading">
                    <UsersTilesList 
                        :key="this.componentKey" :can-edit="showEdit" :can-delete="showDelete"
                        :name-filter="updatedEmailFilter" :selected-roles="selectedRoles" :items-per-page="20" @delete-u="deleteUser" :users="users" :roles="roles">
                    </UsersTilesList>
                </span>
                <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
            </div>
        </div>
    </BasePage>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';

  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import LoginUsers from '@/partials/users/LoginUsers.vue';
  import UsersSidebar from '@/partials/users/UsersSidebar.vue';
  import UsersTilesList from '@/partials/users/UsersTilesList.vue';
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  
  export default {
    name: 'UsersListPage',
    data() {
      return {
        componentKey: 0,
        users: [],
        roles: [],
        showFilters: false,
        showAdd: false,
        showEdit: false,
        isLoading: false,
        isEditing: false,
        selectedRoles: {},
        rolesOptions: [],
        updatedEmailFilter: ''
      }
    },
    components: {
        BasePage,
        PageTitle,
        LoginUsers,
        UsersSidebar,
        AddMainButton,
        UsersTilesList,
    },
    methods: {
        updateValues(){
            loadFromLocalStorage('users', 'users', this);
            this.componentKey++;
        },
        updateUserRoles(updatedRoles) {
            this.selectedRoles = { ...updatedRoles };
        },
        updateEmail(updatedEmailFilter) {
            this.updatedEmailFilter = updatedEmailFilter;
        },
        createUser(){
            this.$router.push('/users/create-user'); // Or use a custom route if defined
        },
        async getUsers() {
            this.users = await fetchData(`users`, 'users', 'users', this);
        },
        async getRoles() {
            const response = await fetchData(`roles`, 'userRoles', 'roles', this);
            this.rolesOptions = response.map(role => role.name);
            for (const role in this.rolesOptions) {
                this.selectedRoles[this.rolesOptions[role]] = true;
            }
        },
        async deleteUser(id){
            const updatedUsers = this.users.filter((user) => user.id !== id);
            localStorage.setItem('users', JSON.stringify(updatedUsers));
            this.users = updatedUsers
            this.componentKey++
        },
        getdata(){
            Promise.all([
                this.getRoles(),
                this.getUsers()
            ]).then((results) => {
              this.componentKey++
            })
        }
    },
    
    setup() {
  
      const sidebarOpen = ref(false)
      return {
        sidebarOpen,
      }  
    },
    async created() {
        loadFromLocalStorage('users', 'users', this);
        hasSpecificPermission('showFilters', 'page-filter', ["view_users_filter"], this)
        hasSpecificPermission('showAdd', 'add', ["add_user"], this)
        hasSpecificPermission('showEdit', 'edit', ["edit_user"], this)
        hasSpecificPermission('showDelete', 'delete', ["delete_user"], this)

        loadFromLocalStorage('roles', 'roles', this);
        this.rolesOptions = this.roles.map(role => role.name);
        for (const role in this.rolesOptions) {
            this.selectedRoles[this.rolesOptions[role]] = true;
        }
        this.getdata()
    },
  }
  </script>