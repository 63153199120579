<template>
    <div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoginUsers',
    props: {
      users_: Array
    },
    data() {
      return {
        ws: null,
        users: this.users_,
      };
    },
    async created() {
      this.setupWebSocket();
    },
    beforeUnmount() {
      if (this.$root.$ws) {
        this.$root.$ws.close();
      }
    },
    methods: {
      setupWebSocket() {
        const token = localStorage.getItem('token')
        this.$root.$ws = new WebSocket(`wss://dev-api.afieconsultores.com/ws?token=${token}`);
  
        this.$root.$ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.type === 'USER_STATUS_CHANGE') {
            this.updateUserStatus(message.user);
          }
        };
      },
      updateUserStatus(updatedUser) {
        const userIndex = this.users.findIndex(user => user.id === updatedUser.id);
        if (userIndex !== -1) {
            // Remove the item from the array
            this.users.splice(userIndex, 1);
            // Insert the updated item at the original index
            this.users.splice(userIndex, 0, updatedUser);
            localStorage.setItem('users', JSON.stringify(this.users));
            this.$emit('update');
        }
        else {
          this.users.push(updatedUser);
          localStorage.setItem('users', JSON.stringify(this.users));
        }
      },
    },
  };
  </script>
  