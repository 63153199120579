<template>
    <div class="w-full bg-gray-300 rounded-full h-2 relative">
      <div
        class="bg-green-500 h-2 absolute top-0 left-0"
        :class="{
          'rounded-l-full': doneProgress > 0 && doneProgress + progressWidth !== 100,
          'rounded-full': doneProgress === 100,
          'rounded-l-full': doneProgress > 0
        }"
        :style="{ width: doneProgress + '%' }"
      ></div>
      <div
        class="bg-blue-500 h-2 absolute top-0"
        :class="{
          'rounded-l-full': doneProgress === 0,
          'rounded-r-full': progressWidth > 0 && doneProgress + progressWidth !== 100,
          'rounded-full': progressWidth === 100,
          'rounded-r-full': doneProgress > 0 && doneProgress + progressWidth === 100
        }"
        :style="{ width: progressWidth + '%', left: doneProgress + '%' }"
      ></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProgressBar',
    props: {
      progressWidth: {
        type: Number,
        required: true,
        validator(value) {
          return value >= 0 && value <= 100;
        }
      },
      doneProgress: {
        type: Number,
        required: true,
        validator(value) {
          return value >= 0 && value <= 100;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  