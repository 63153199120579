<template>
  <button v-if="showCancelButton" 
    class="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 mr-2" 
    @click.prevent.stop="clickHandler">
    Cancelar
  </button>
  <button class="btn bg-indigo-500 hover:bg-indigo-600 text-white" type="submit">
      <span v-if="!isLoading">{{ $t('buttons.submit') }}</span>
      <span v-else>
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
  </button>
</template>
  
<script>
  export default {
    name: 'SubmitMainButton',
    props: {
      isLoading: Boolean,
      showCancelButton: {
        type: Boolean,
        default: function(){
          return false
        }
      },
      clickHandler: {
        type: Function,
        required: false,
      },
    },
  };
</script>
  