<template>
    <div class="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header class="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <div class="flex justify-between">
          <h2 class="font-semibold text-slate-800 dark:text-slate-100 content-center">{{title}}</h2>
        </div>
      </header>
      <div class="p-3">
        <span v-if="this.elements?.length < 1" class="content-center">{{ emptyLabel }}</span>
        <div v-if="!isDraggable">
          <div v-for="(item, index) in this.elements" :key="index">
            <header class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm font-semibold p-2">
              <div class="flex justify-between	">
                <span class="content-center"><button :disabled="!canEdit" @click.prevent.stop="edit(item)">{{ getPropertyMethod(item, this.propertyKey) }}</button></span>
                <div class="flex">
                  <button v-if="canEdit" class="text-white-500 hover:text-white-600 rounded-full">
                    <svg @click.stop="removeElement(index)" class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                      <path  class="text-red-500" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                      <path class="text-red-500" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                    </svg>
                  </button>
                </div>
              </div>
            </header>          
          </div>
        </div>
        <draggable v-else
          v-model="this.elements" 
          group="people" 
          @start="drag=true" 
          @end="drag=false, updateOrder()" 
          item-key="id">
          <template #item="{element, index}">
            <header class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm font-semibold p-2">
                <div class="flex justify-between	">
                  <div class="flex content-center">
                    <div class="drag-handle w-9 h-9 rounded-full shrink-0 bg-indigo-500 my-2 mr-3 cursor-move flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                      </svg>
                    </div>
                    <div class="content-center">
                      <button @click.prevent.stop="edit(element)">{{ getPropertyMethod(element, this.propertyKey) }}</button>
                    </div>
                  </div>
                  <div class="flex">
                    <button class="text-white-500 hover:text-white-600 rounded-full">
                      <svg @click.stop="removeElement(index)" class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                        <path  class="text-red-500" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                        <path class="text-red-500" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                      </svg>
                    </button>
                  </div>
                </div>
              
            </header>
          </template>
        </draggable>
      </div>
    </div>
  </template>
  
  <script>
  import draggable from 'vuedraggable'
  import { getProperty } from '@/utils/getProperty.js'

  export default {
    name: 'SubCategoriesTable',
    components: {
      draggable
    },
    props: {
      isDraggable: {
        type: Boolean,
        default: true
      },
      canEdit: {
        type: Boolean,
        default: function(){
          return false
        }
      },
      title: String,
      values: Array,
      emptyLabel: String,
      isLoading: Boolean,
      propertyKey: String
    },
    data(){
      return {
        elements: this.values
      }
    },
    methods: {
      edit(item){
        this.$emit('edit', item);
      },
      updateOrder(){
        this.$emit('updateorder:model', this.elements);
      },
      removeElement(index){
        this.updateModel(index);
      },
      updateModel(option) {
        this.$emit('update:model', option);
      },
      getPropertyMethod(item, propertyKey) {
        return getProperty(item, propertyKey)
      }
    },
  }
  </script>