<template>
    <form @submit.prevent="handleSurvey">
        <div class="px-5 py-4">
            <div class="space-y-3">
                <div>
                    <label class="block text-sm font-medium mb-1" for="title">Titulo <span class="text-rose-500">*</span></label>
                    <input id="title" v-model="this.formData.title" class="form-input w-full px-2 py-1" type="text" required />
                </div>
                <div>
                    <label class="block text-sm font-medium mb-1" for="content">Contenido</label>
                    <textarea id="content" v-model="formData.content" class="form-textarea w-full px-2 py-1" placeholder="Descripcion" rows="4"></textarea>
                </div>
                <div>
                    <label class="block text-sm font-medium mb-1">Imagenes</label>
                    <label class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 mr-2" >
                        <p>{{ `${selectedImages.length} imagenes seleccionadas` || 'Elegir hasta 5 archivos' }}</p>
                        <input class="hidden" type="file" id="images" @change="handleFC($event, 'images')" ref="imagefileInput" multiple accept="image/*">
                    </label>

                </div>
                <div>
                    <label class="block text-sm font-medium mb-1">Videos</label>
                    <label class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2 mr-2" >
                        <p>{{ `${selectedVideos.length} videos seleccionados` || 'Elegir hasta 2 archivos' }}</p>
                        <input class="hidden" type="file" id="videos" @change="handleFC($event, 'videos')" ref="videofileInput" multiple accept="video/*">
                    </label>
                </div>

                <!-- Preview Selected Files -->
                <div v-if="selectedImages.length || selectedVideos.length">
                    <div v-if="selectedImages.length">
                        <h4 class="font-semibold">Imagenes seleccionadas:</h4>
                        <ul class="list-disc pl-5">
                            <li v-for="(image, index) in selectedImages" :key="index">
                                {{ image.name }}
                                <button @click.prevent.stop="removeFile('images', index)" class="text-red-500 hover:underline ml-2">Remover</button>
                            </li>
                        </ul>
                    </div>
                    <div v-if="selectedVideos.length">
                        <h4 class="font-semibold mt-4">Videos seleccionados:</h4>
                        <ul class="list-disc pl-5">
                            <li v-for="(video, index) in selectedVideos" :key="index">
                                {{ video.name }}
                                <button @click.prevent.stop="removeFile('videos', index)" class="text-red-500 hover:underline ml-2">Remover</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
                <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {feedbackModalOpen = false}"/>
            </div>
        </div>
    </form>
</template>



<script>
    import { handleMultipleFileChange } from '@/utils/handleDocuments.js';

    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    
    export default {
        name: 'NotificationsForm',
        props: {
            notifications_: Array,
            isLoading: Boolean,
            formData_: {
                type: Object,
                default: function(){
                    return {
                        title: null,
                        content: null,
                        images: [],
                        videos: []
                    }
                }
            }
        },
        components: {
            SubmitMainButton
        },
        data(){
            return {
                formData: this.formData_,
                imageFormats: ['.jpg', '.png'],
                videoFormats: ['.mp4', '.avi', '.mov'],
                selectedImages: [],
                selectedVideos: [],
            }
        },
        methods: {
            removeFile(type, index) {
                if (type === 'images') {
                    this.selectedImages.splice(index, 1);
                } else if (type === 'videos') {
                    this.selectedVideos.splice(index, 1);
                }
            },
            handleFC(event) {
                const type = event.target.id; // 'images' or 'videos'
                const validFormats = type === 'images' ? this.imageFormats : this.videoFormats;
                const maxFiles = type === 'images' ? 5 : 2;

                handleMultipleFileChange(event, this, validFormats, maxFiles, type);
            },
            handleSurvey(){
                this.formData.images = this.selectedImages;
                this.formData.videos = this.selectedVideos;
                this.$emit('handle-click:value', this.formData)
            },
            closeModal(){
                this.formData = {
                    title: null,
                    content: null,
                    images: [],
                    videos: []
                } 
                this.selectedImages = [];
                this.selectedVideos = [];
                this.$emit('close-modal')
            },
        }
    }
</script>