<template>
  <ModalBasic id="modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" :title="titleModal">
      <!-- Modal content -->
      <form @submit.prevent="add">
        <div class="px-5 py-4">
            <div class="space-y-3">
              <div>
                <label class="block text-sm font-medium mb-1" for="content">Contenido <span class="text-rose-500">*</span></label>
                <input id="content" v-model="content" class="form-input w-full px-2 py-1" type="text" required />
              </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
              <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {modalOpen = false}" />
            </div>
        </div>
      </form>
  </ModalBasic>
  <div class="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 min-h-80">
    <header class="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
      <div class="flex justify-between">
        <h2 class="font-semibold text-slate-800 dark:text-slate-100 content-center">{{title}}</h2>
        <button v-if="canAdd" class="text-white-500 hover:text-white-600 rounded-full">
          <svg @click.stop.prevent="modalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
        </button>
      </div>
    </header>
    <div class="p-3">
      <div v-for="item in this.list" :key="item.content">
        <header class="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm font-semibold p-2">
          <div class="flex justify-between	">
            <span class="content-center">{{item.content}}</span>
            <div class="flex">
              <div class="pr-2"></div>
              <!-- <button class="text-white-500 hover:text-white-600 rounded-full">
                <svg @click.stop.prevent="remove(index)" class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                  <path  class="text-red-500" d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                  <path class="text-red-500" d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
                </svg>
              </button> -->
            </div>
          </div>
          <div>Creada el: {{ item.createdAt }}</div>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
import ModalBasic from '@/components/ModalBasic.vue';
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

export default {
  name: 'InteractionsTable',
  emits: ['add:model', 'remove:model'],
  components: {
    ModalBasic,
    SubmitMainButton
  },
  props: {
    isLoading: Boolean,
    title: String,
    titleModal: String,
    canAdd: Boolean,
    list: Array,
  },
  data(){
    return {
      modalOpen: false,
      content: '',
    }
  },
  methods: {
    add(){
      this.modalOpen = false;
      this.$emit('add:model', this.content);
      this.content = '';
    },
    remove(index){
      this.$emit('remove:model', index);
    },
  },
}
</script>