export function extractUniquePermissions(category) {
    const user = JSON.parse(localStorage.getItem('me')) || {};
    // Use a Set to collect unique permissions
    const uniquePermissions = new Set();

    // Iterate through each role and its permissions
    if(user.roles === undefined){
      return []
    }
    user.roles.forEach(role => {
      role.permissions.forEach(permission => {
        if(category === null){
          uniquePermissions.add(JSON.stringify(permission));
        }
        if (permission.category === category) {
          uniquePermissions.add(JSON.stringify(permission)); // Use JSON.stringify to compare objects
        }
      });
    });

    // Convert Set back to an array of unique permission objects
    return Array.from(uniquePermissions).map(permission => JSON.parse(permission));
}