<template>
  <div>
    <input type="checkbox" name="board-switch" id="board-switch" :checked="boardMode" class="light-switch sr-only" @change="updateModel()"/>
    <label class="flex items-center justify-center cursor-pointer w-8 h-8 bg-slate-200 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full" for="board-switch">
      <svg class="w-4 h-4" :class="isBoard ? 'block': 'hidden'" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <path class="fill-current text-slate-400" d="M13.5 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm-11-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
        <path class="fill-current text-slate-500" d="M6.5 3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm-4 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1zm8 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1z"/>
      </svg>
      <svg class="w-4 h-4" :class="isBoard ? 'hidden': 'block'" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <path class="fill-current text-slate-400" fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
      </svg>
      <span class="sr-only">Switch to board / list board</span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'BoardToggle',
    props: {
      isBoard: Boolean
    },
    data(){
      return {
        boardMode: this.isBoard,
      }
    },
    methods: {
      updateModel() {
        this.boardMode = !this.boardMode;
        this.$emit('update:model', this.boardMode);
      },
    }
  }
</script>
