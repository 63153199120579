<template>
    <form @submit.prevent="isEditing ? submitEditRole(): addRole()">
        <div class="px-5 py-4">
            <div class="space-y-3">
                <div>
                    <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                    <input id="name" v-model="formData.name" class="form-input w-full px-2 py-1" type="text" required />
                </div>
                <div>
                    <label class="block text-sm font-medium mb-1" for="description">Descripcion <span class="text-rose-500">*</span></label>
                    <textarea id="description" v-model="formData.description" class="form-textarea w-full px-2 py-1" rows="4"></textarea>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-x-4 mb-4 gap-y-4" >
                <div
                class="text-xs text-black"
                v-for="permission in this.permissions"
                :key="permission.category">
                    <label class="block mb-1 text-rose-500">{{ permission.category }}</label>
                    <div v-for="p in permission.permissions" :key="p.name" class="text-black dark:text-white mb-2">
                    <input class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" 
                    :checked="formData.permissions.includes(p.id)"
                    type="checkbox"  @change="updateModel(p.id)"> 
                    {{ p.friendlyName}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
            <div class="flex flex-wrap justify-end space-x-2">
                <SubmitMainButton :is-loading="isLoading" :show-cancel-button="true" :click-handler="() => {modalOpen = false, clean()}" />
            </div>
        </div>
    </form>
</template>

<script>
    import { postData } from '@/utils/postData.js';
    import { editData } from '@/utils/editData.js';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    
    export default {
        name: 'RolesForm',
        props: {
            editingId: String,
            isEditing: Boolean,
            permissions: Array,
            roles_: Array,
            formData_: Object
        },
        components: {
            SubmitMainButton
        },
        data(){
            return {
                roles: this.roles_,
                formData: this.formData_,
                isLoading: false
            }
        },
        methods: {
            updateModel(option){
                const index = this.formData.permissions.indexOf(option);
                if (index === -1) {
                    // If the option is not already in the array, push it
                    this.formData.permissions.push(option);
                } else {
                    // If the option is already in the array, remove it
                    this.formData.permissions.splice(index, 1);
                }   
            },
            async addRole() {
                const response = await postData('roles/', this.formData, this, true)
                this.roles.push(response)
                localStorage.setItem('userRoles', JSON.stringify(this.roles));
                this.$emit('emit-roles:value', {roles: this.roles})
            },
            async submitEditRole(){
                await editData(`roles/${this.editingId}`, this.formData, 'El rol se a editado exitosamente.', 'roles', 'userRoles', this, true, true);
                this.$emit('emit-roles:value', {roles: this.roles})
            }
        }
    }
</script>