<template>
    <form @submit.prevent="handleSubmit" :key="componentKey">
        <span v-for="element, index in clientSurveys" :key="element.name">
            <label class="block text-sm font-medium mb-1" for="name">{{ index + 1 }}.- {{ element.name }}<span v-if="element.surveyMetaData.required" class="text-rose-500">*</span></label>
            <label class="block text-sm font-medium mb-1" for="name">{{ element.surveyMetaData.question }}<span v-if="element.surveyMetaData.required" class="text-rose-500">*</span></label>
            <input class="form-input w-full" v-if="element.surveyMetaData.type != 'options' && element.surveyMetaData.type != 'float'" 
                v-model="element.answer"
                :type="element.surveyMetaData.type" :disabled="element.surveyMetaData.requestDuringSignature || disableInputs"  :required="element.surveyMetaData.required && !element.surveyMetaData.requestDuringSignature">
            <input class="form-input w-full" v-else-if="element.surveyMetaData.type == 'float'"
                v-model="element.answer"
                type="number" step="0.01" :disabled="element.surveyMetaData.requestDuringSignature || disableInputs"  :required="element.surveyMetaData.required && !element.surveyMetaData.requestDuringSignature">
            <DropdownFull :ref="'child_' + index" v-else 
                :init-value="convertOptions(element.surveyMetaData.options).findIndex(c => c.name == element.answer)"
                :disable-inputs="element.surveyMetaData.requestDuringSignature || disableInputs"
                :dropdownOptions="convertOptions(element.surveyMetaData.options)" :model="element.answer" @update:model="updateAnwer($event, index)"/>
            <label class="flex items-center mt-2">
                <input type="checkbox" :disabled="disableInputs" :checked="element.surveyMetaData.requestDuringSignature" class="form-checkbox w-5 h-5 rounded-full peer" @change="onPressed(index)"/>
                <span class="font-medium text-slate-800 dark:text-slate-100 ml-2">Solicitar durante el proceso de Firma</span>
            </label>
        </span>
        
        <div class="flex flex-wrap justify-end space-x-2">
            <SubmitMainButton :show-cancel-button="true" :click-handler="forceRender" />
        </div>
      
    </form>
</template>

<script>
    import DropdownFull from '@/components/DropdownFull.vue';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

    export default {
        name: 'ClientSurveyForm',
        props: {
            disableInputs: Boolean,
            clientSurveys_: Array
        },
        components:{
            DropdownFull,
            SubmitMainButton
        },
        data(){
            return {
                componentKey: 0,
                originalSurveys: JSON.parse(JSON.stringify(this.clientSurveys_)),
                clientSurveys: this.clientSurveys_
            }
        },
        methods: {
            handleSubmit(){
                this.$emit('handle-submit:value', this.clientSurveys)
            },
            forceRender(){
                this.clientSurveys = JSON.parse(JSON.stringify(this.originalSurveys));
                this.componentKey++
            },
            onPressed(index){
                this.clientSurveys[index].surveyMetaData.requestDuringSignature = 
                    !this.clientSurveys[index].surveyMetaData.requestDuringSignature;
                this.clientSurveys[index].answer = null
                this.componentKey++
            },
            updateAnwer(newAnswer, index){
                this.clientSurveys[index].answer = newAnswer.name == 'Elegir' ? null : newAnswer.name;
            },
            convertOptions(options){
                const parsedOptions = options == null ? []:JSON.parse(options.replace(/'/g, '"')).map((item, index) => ({
                    id: index + 1,
                    name: item
                }))
                
                parsedOptions.unshift({ id: 0, name: 'Elegir' });
                
                return parsedOptions
            }
        },
    }
</script>